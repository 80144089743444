<template>
    <Attr :widget_id="widget_id" :attr_id="attr_id">
        <div class="wj-labeled-input attr-text" :style="attr.cssStyle||'max-width: 8rem !important;'" v-wjTooltip="tooltip()">
            <wj-input-number 
                :class="'attr-element '+attr.cssClass"
                :id="attr.html_id"
                :isReadOnly="isReadOnly"
                :step="attr.step||0"
                :format="attr.format||'n0'"
                :isRequired="false"
                :value="doc[binding]"
                :valueChanged="attr_set"
                v-wjContextMenu="'ContextWidgetMenuId'"
            />
                <!-- :format="'p0'" -->
                <!-- :step=".05" -->
                <!-- :min="0" -->
                <!-- :max=".2" -->
            <label v-if="attr.title && !attr.hideTitle" :for="attr.html_id">{{ attr.title }}</label>
            <!-- <div class="wj-error" tabindex="-1">We do need your name...</div> -->
        </div>
    </Attr>
</template>

<script>
import { useMainStore } from '@/stores/mainStore'

export default {
    props: [
        'widget_id',
        'attr_id',
    ],
    data: () => ({ 
        WIDGET: {},
        // value: 0,
        doc: {},
        binding: '',
    }),
    setup() {
        const store = useMainStore();
        return { store }
    },
    created() {
        // console.log(`created ${this.attr_id}`)
        this.WIDGET = this.store.findWidget(this.widget_id).WIDGET
        this.content_Changed()
    },
    methods: {
        content_Changed() {
            this.store.widget_attr_set(this)
        
            const [doc, binding] = this.store.attr_get_link(this.WIDGET, this.attr)
            this.doc = doc, this.binding = binding

            if (isNaN(this.doc[this.binding])) {
                this.doc[this.binding] = 0
            }
            this.doc[this.binding] = +this.doc[this.binding]

            // let value = this.store.attr_get(this.WIDGET, this.attr)
            // if (isNaN(value)) {
            //     value = 0
            // }
            // this.value = +value
        },
        attr_set(input) {
            this.store.attr_set(this.WIDGET, this.attr, input.value)
        },
        tooltip() {
            return this.store.tooltip_attr(this)
        },
    },
	computed: {
        isReadOnly() {
            return this.WIDGET.isReadOnly||this.attr.isReadOnly||this.attr.fieldMapping?.use||false
        },
	},
}
</script>

<style scoped>
.attr-text {
    width:-webkit-fill-available !important;
}
</style>