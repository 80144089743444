<template>
    <Attr :widget_id="widget_id" :attr_id="attr_id">
        <div v-wjTooltip="attr.tooltip"
            v-wjContextMenu="'ContextWidgetMenuId'"
        >
            <label class="label-attr" v-if="attr.title && !attr.hideTitle" :for="attr.html_id">{{ attr.title }}</label>

            <div class="grid-top-line" ref="attr_top_line" v-if="!attr?.hideCommandPanel">
                <div class="commandPanel-menu" v-if="commandPanel.length">
                    <component v-for="attrM in commandPanel"
                        :key="attrM.html_id"
                        :widget_id="widget_id" 
                        :is="attrM.component"
                        :attr_id="attrM.id"
                        :initialAttr="attrM" 
                    />
                </div>
            
                <div class="widget_menu mr-2">
                    <wj-menu id="actionMenuId" :displayMemberPath="'titleAction'" :subItemsPath="'attrs'" :showDropDownButton="false"
                        v-if="actionMenu.length"
                        :openOnHover="true" :isAnimated="false" :itemsSource="actionMenu" :initialized="initMenu" :itemClicked="menuNodeClicked"
                    >
                        <i class="pi pi-bars pi-bold"></i>
                    </wj-menu>
                </div>
            </div>

            <Codemirror
                :id="attr.html_id"
                v-model:value="content_str"
                v-visible="handleVisibility"
                :options="cmOptions"
                @change="attr_set"
                @ready="editorReady"
            />
        </div>
    </Attr>
</template>

<script>
import { useMainStore } from '@/stores/mainStore'

import Codemirror from "codemirror-editor-vue3";
import "codemirror/mode/javascript/javascript.js";
import "codemirror/mode/sql/sql.js";
import "codemirror/theme/dracula.css";
import "codemirror/addon/fold/foldcode";
import "codemirror/addon/fold/foldgutter";
import "codemirror/addon/fold/brace-fold";
import "codemirror/addon/fold/foldgutter.css";
import "codemirror/theme/neo.css";

export default {
    props: [
        'widget_id',
        'attr_id',
    ],
    data: () => ({ 
        WIDGET: {},
        content_str: '',
        JS_deep: 3,
        cmOptions: {
            mode: {'name': 'javascript', 'json': true}, // Language mode
            // mode: "sql", // Language mode
            theme: "neo", // Theme dracula
            lineNumbers: true, // Show line number
            smartIndent: true, // Smart indent
            indentUnit: 4, // The smart indent unit is 2 spaces in length
            foldGutter: true, // Code folding
            gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
            styleActiveLine: true, // Display the style of the selected row
            // autofocus: true,
            // fontsize: "small",
        },

        commandPanel: [],
        actionMenu: [],
    }),
    setup() {
        const store = useMainStore();
        return { store }
    },
    created() {
        // console.log(`created ${this.attr_id}`)
        this.WIDGET = this.store.findWidget(this.widget_id).WIDGET
        this.content_Changed()
    },
    methods: {
        content_Changed() {
            this.store.widget_attr_set(this)
        
            let content = this.store.attr_get(this.WIDGET, this.attr) || {}
            this.content_str = this.store.JS_stringify(content, this.JS_deep)
            this.set_commandPanel()
        },
        attr_set(input) {
            let new_content = this.store.JS_parse(this.content_str)
            this.store.attr_set(this.WIDGET, this.attr, new_content)
        },
        editorReady(editor) {
            this.editor = editor
        },
        handleVisibility(isVisible) {
            this.isVisible = isVisible
            if (isVisible && this.editor) {
                this.editor.refresh()
            }
        },
        set_commandPanel() {
            let commandPanel = []

            // let need_separator = commandPanel.length
            // if (this.attr.groupMenu?.commandPanel) {
            //     this.attr.groupMenu.commandPanel.forEach(command_ => {
            //         const command = command_, v_if = command_['v-if'] // { 'v-if': v_if, ...command } = command_
            //         if (!command.autoGeneratedButton) {
            //             command.visible = this.store.eval_value(this.WIDGET, v_if, eval_params)
            //             if (need_separator) {
            //                 commandPanel.push({autoGeneratedButton:true})
            //                 need_separator = false
            //             }
            //             commandPanel.push(command)
            //         }
            //     })
            // }
            // this.store.prepare_attrs(commandPanel, this.attr.html_id + (++this.ind_commandPanel))

            this.actionMenu = this.store.actionMenu_from_commandPanel(commandPanel)

            const action = [
                { 'attr_type':'AttrButton', 'binding':this.attr.binding, 'title': 'JS deep: 0', 'command': 'onJS_deep__Click', 'params':{'JS_deep':0} },
                { 'attr_type':'AttrButton', 'binding':this.attr.binding, 'title': 'JS deep: 1', 'command': 'onJS_deep__Click', 'params':{'JS_deep':1} },
                { 'attr_type':'AttrButton', 'binding':this.attr.binding, 'title': 'JS deep: 2', 'command': 'onJS_deep__Click', 'params':{'JS_deep':2} },
                { 'attr_type':'AttrButton', 'binding':this.attr.binding, 'title': 'JS deep: 3', 'command': 'onJS_deep__Click', 'params':{'JS_deep':3} },
                { 'attr_type':'AttrButton', 'binding':this.attr.binding, 'title': 'JS deep: 4', 'command': 'onJS_deep__Click', 'params':{'JS_deep':4} },
                { 'attr_type':'AttrButton', 'binding':this.attr.binding, 'title': 'JS deep: 5', 'command': 'onJS_deep__Click', 'params':{'JS_deep':5} },
                { 'attr_type':'AttrButton', 'binding':this.attr.binding, 'title': 'JS deep: 6', 'command': 'onJS_deep__Click', 'params':{'JS_deep':6} },
                { 'attr_type':'AttrButton', 'binding':this.attr.binding, 'title': 'JS deep: 7', 'command': 'onJS_deep__Click', 'params':{'JS_deep':7} },
            ]
            for (let command of action) {
                this.actionMenu.push(this.store.prepare_actionMenu(command))
            }

            this.commandPanel = commandPanel
        },
        menuNodeClicked(menu) {
            let action = menu.itemsSource[menu.selectedIndex]
            let vueObj = this.WIDGET.vueObj
            this.store.executeStoreMethod(vueObj, action)
        },
    },
    components: {
      Codemirror,
    },
}
</script>

<style scoped>
.codemirror-container {
    margin-top: 20px;
}
</style>