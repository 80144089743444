<template>
    <div
        v-if="visible"
        class="dropdown"
        :class="{
            onTop: position.onTop,
            onBottom: !position.onTop
        }"
        ref="dropdown"
        :style="computedPosition"
        @mouseenter="dropdown_mouseenter($event, true)"
        @mouseleave="dropdown_mouseleave($event, true)"
    >
        <div class="dropdown-commands">
            <div class="commandPanel-row" v-if="commandPanel_row2.length">
                <div class="commandPanel-title">ROW:</div>
                <component
                    v-for="attrM in commandPanel_row2"
                    :key="attrM"
                    :widget_id="widget_id" 
                    :is="attrM.component"
                    :attr_id="attrM.id"
                    :initialAttr="attrM"
                />
            </div>

            <div class="commandPanel-cell" v-if="commandPanel_cell2.length">
                <div class="commandPanel-title">CELL:</div>
                <component
                    v-for="attrM in commandPanel_cell2"
                    :key="attrM"
                    :widget_id="widget_id" 
                    :is="attrM.component"
                    :attr_id="attrM.id"
                    :initialAttr="attrM"
                />
            </div>
        </div>

        <div v-if="showValueHTML"
            ref="editorHTML"
            id="editorHTML"
            class="editorHTML"
            contenteditable="true"
            @input="onInput_valueHTML"
            v-html="valueHTML"
        />
            <!-- @blur="convertToPlainText" -->
        
        <textarea v-if="showValueHTML" 
            class="value" 
            v-model="value" 
            @input="onInput_value"
            rows="3"
            cols="50"
        />

        <ul class="list-container" @pointerdown="handlePointerDown">
            <li v-for="(item, index) in items" :key="index" @click="selectItem(item)" class="list-item">
                <!-- <span v-html="item.highlightedTitle"></span> -->
                <span class="title" v-html="item.highlightedTitle"></span>
                <span class="id" v-html="item.highlightedID" v-if="item.highlightedID && item.highlightedID !== item.highlightedTitle" ></span>
            </li>
        </ul>
    </div>
</template>

<script>
import { useMainStore } from '@/stores/mainStore'

export default {
    data() {
        return {
            value: "",
            valueHTML: "",
            itemsSource: [],
            showValueHTML: false,

            // widget_id:'dropdown',
            visible: false,
            position: { top: 0, left: 0 },
            items: [],
            onSelect: null,
            onEditFinish: null,
            
            commandPanel_row: [],
            commandPanel_row2: [],
            commandPanel_cell: [],
            commandPanel_cell2: [],

            ind_commandPanel: 0,

            itemsSource_segment: null,
            marginLeft: 20,
            marginTop: -1,
        }
    },
    setup() {
        const store = useMainStore()
        return { store }
    },
    created() {
        // this.store.WIDGETS[this.widget_id] = {
        //     id: this.widget_id,
        //     vueObj: this,
        //     attrs_vueObj: {
        //         WIDGET: this,
        //     },
        // }
        document.addEventListener('mousemove', (event) => {
            this.store.mouseX = event.clientX
            this.store.mouseY = event.clientY

            // console.log(' - ' + this.store.mouseX)

        })

        // document.addEventListener('keydown', function(event) {
        //     if (event.code === 'Space') {
        //         event.preventDefault()

        //         this.set_use(e, checked, binding, r1, r2)
        //         console.log('Spacebar pressed!')
        //     }
        // })
    },
    mounted() {
        this.mouseenter = (event, isDropdown=false) => this.dropdown_mouseenter(event, isDropdown)
        this.mouseleave = (event, isDropdown=false) => this.dropdown_mouseleave(event, isDropdown)
        this.blur = (event) => this.dropdown_blur(event)
    },
    methods: {
        show(check_mouse_isInside=false, force=false) {
            const dropdown = this
       
            dropdown.clear_timer()
            dropdown.set_position()
            const canBeVisible = dropdown.divCell && (!check_mouse_isInside || dropdown.mouse_isInside)
            if (!canBeVisible) {
                dropdown.hide()
            } else if (dropdown && (!dropdown.visible || force)) {
                dropdown.show_timerId = setTimeout(() => {
                    if (dropdown.WIDGET.close) {
                        dropdown.hide()
                        return
                    }
                    const cssClass = 'pi p-button-white p-button-text0 mr-0 '

                    dropdown.commandPanel_row2 = []
                    for (const attrMenu of dropdown.commandPanel_row) {
                        dropdown.commandPanel_row2.push({
                            ...attrMenu,
                            cssClass: cssClass + (attrMenu.cssClass || ''),
                            ...dropdown.parent_attr.cell,
                            'binding':dropdown.binding,
                        })
                    }

                    dropdown.commandPanel_cell2 = []
                    for (const attrMenu of dropdown.commandPanel_cell) {
                        dropdown.commandPanel_cell2.push({
                            ...attrMenu,
                            cssClass: cssClass + (attrMenu.cssClass || ''),
                            ...dropdown.parent_attr.cell,
                            'binding':dropdown.binding,
                        })
                    }

                    dropdown.store.prepare_attrs(dropdown.commandPanel_row2, 'dropdown' + (dropdown.ind_commandPanel))
                    dropdown.store.prepare_attrs(dropdown.commandPanel_cell2, 'dropdown' + (dropdown.ind_commandPanel))

                    this.showValueHTML = String(this.value).startsWith('=') && !dropdown.attr_col.no_showValueHTML;
                    if (this.showValueHTML) {
                        this.set_showValueHTML()
                    }
                    
                    dropdown.visible = true

                    // dropdown.addObserver()
                    dropdown.set_check_position_timerId = setInterval(() => {
                        dropdown.check_position()
                    }, 100)
                }, 100)
            }
        },
        set_showValueHTML(focus=true) {
            this.showValueHTML = true;
            this.get_valueHTML();
            if (focus) {
                this.$nextTick(() => {
                    if (this.$refs.editorHTML) {
                        this.$refs.editorHTML.focus();
                    }
                });
            }
        },
        addObserver() {
            const resizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    console.log(':', entry.target);
                    console.log(':', entry.contentRect);
                }
            });
            resizeObserver.observe(dropdown.divCell);

            const intersectionObserver = new IntersectionObserver(entries => {
                for (let entry of entries) {
                    console.log(':', entry.target);
                    console.log(':', entry.boundingClientRect);
                }
            });
            intersectionObserver.observe(dropdown.divCell);

            const mutationObserver = new MutationObserver(mutations => {
                mutations.forEach(mutation => {
                    if (mutation.attributeName === 'style') {
                        if (this.visible) {
                            this.set_position()
                        }
                    }
                })
            })
            mutationObserver.observe(dropdown.divCell, {
                attributes: true,
                attributeFilter: ['style']
            })
        },
        hide() {
            if (this.visible) {
                this.visible = false
                this.clear_timer()
            }
        },
        selectItem(item) {
            if (this.onSelect) {
                this.onSelect(item)
            }
            this.hide_items()
        },
        handlePointerDown(e) {
            this.preventBlur = true;
        },
        dropdown_mouseenter(e, isDropdown=false) {
            this.clear_timer()
            // if (!isDropdown) {
            //     this.clear_timer()
            //     this.show()
            // }
        },
        dropdown_mouseleave(e, isDropdown=false) {
            this.clear_timer()
            if (this.visible && !this.activeEditor) {
                this.hide_timerId = setTimeout(() => {
                    this.hide()
                }, 500)
            }

            // this.divCell.style.border = 'none'
        },
        dropdown_blur(e) {
            this.hide_items()
        },
        hide_items() {
            this.hide_items_timerId = setTimeout(() => {
                this.items = []
            }, 500)
        },
        clear_timer() {
            if (this.hide_timerId) {
                clearTimeout(this.hide_timerId)
                this.hide_timerId = null
            }
            if (this.show_timerId) {
                clearTimeout(this.show_timerId)
                this.show_timerId = null
            }
            if (this.showForActiveCell_timerId) {
                clearTimeout(this.showForActiveCell_timerId)
                this.showForActiveCell_timerId = null
            }
            if (this.hide_items_timerId) {
                clearTimeout(this.hide_items_timerId)
                this.hide_items_timerId = null
            }
            if (this.set_check_position_timerId && !this.visible) {
                clearTimeout(this.set_check_position_timerId)
                this.set_check_position_timerId = null
            }
        },
        set_position() {
            if (this.divCell) {
                // if (this.position_timerId) {
                //     clearTimeout(this.position_timerId)
                //     this.position_timerId = null
                // }
                // this.position_timerId = setTimeout(() => {
                    // this.divCell.style.border = '1px solid #00f'
                    const position = this.calc_position()
                    if (this.position.top_calc !== position.top_calc || this.position.left_calc !== position.left_calc) {
                        this.position = position
                    }
                    this.position.visibility = 'hidden'

                    this.mouse_isInside = this.store.mouse_isInside(this.divCell)
                // }, 10)
            }
        },
        check_position() {
            if (this.divCell && this.visible) {
                const position = this.calc_position()
                this.correct_position()
                if (this.position.top_calc !== position.top_calc || this.position.left_calc !== position.left_calc) {
                    this.hide()
                }
            }
        },
        calc_position() {
            const rect = this.divCell.getBoundingClientRect(),
            marginTop = this.attr_col?.isNode ? 5 : 0,
            position = {
                top: rect.bottom + window.scrollY + this.marginTop + marginTop,
                left: rect.left + window.scrollX + this.marginLeft,

                // if onTop
                bottom: window.innerHeight - (rect.top + window.scrollY - (this.marginTop + marginTop)),

                divCell: rect,
            }
            position.top_calc = position.top
            position.left_calc = position.left
            return position
        },
        correct_position() {
            const rect = this.$refs.dropdown.getBoundingClientRect(),
            margin = 5

            if (this.position.visibility) {
                if (rect.right > window.innerWidth - margin) {
                    this.position.left -= Math.ceil(rect.right -  (window.innerWidth - margin))
                    if (this.position.left < margin) {
                        this.position.left = margin
                    }
                }
                if (rect.bottom > window.innerHeight - margin) {
                    this.position.onTop = true
                }
            }
            this.position.visibility = null
        },

        // --------------- editorHTML ----------------------------------------------------
        onInput_valueHTML(event) {
            let cursorPos = this.saveCursorPosition();

            this.value = event.target.innerHTML;
            this.convertToPlainText(event.target.innerHTML);
            
            this.$nextTick(() => this.restoreCursorPosition(cursorPos));
            if (this.onEditFinish) {
                this.divCell.innerHTML = this.valueHTML;
                this.onEditFinish(this.value)
            }
        },
        onInput_value(event) {
            // this.value = event.target.innerText;
            this.get_valueHTML();
            if (this.onEditFinish) {
                this.divCell.innerHTML = this.valueHTML;
                this.onEditFinish(this.value)
            }
        },
        get_valueHTML() {
            if (String(this.value).startsWith('=')) {
                this.valueHTML = this.store.check_eval_highlighted(this.WIDGET, this.value, this.itemsSource) || this.value;
            } else if (this.value && this.itemsSource.length) {
                this.valueHTML = this.itemsSource.ids[this.value]?.title || this.value;
            } else {
                this.valueHTML = this.value;
            }
        },
        convertToPlainText(valueHTML) {
            this.value = this.store.convertToPlainText(valueHTML, this.itemsSource);

            this.get_valueHTML();
        },
        saveCursorPosition() {
            let selection = window.getSelection();
            if (!selection.rangeCount) return null;
            let range = selection.getRangeAt(0);
            let preCaretRange = range.cloneRange();
            preCaretRange.selectNodeContents(this.$refs.editorHTML);
            preCaretRange.setEnd(range.endContainer, range.endOffset);
            return preCaretRange.toString().length;
        },
        restoreCursorPosition(pos) {
            let node = this.$refs.editorHTML;
            let selection = window.getSelection();
            let range = document.createRange();
            let charCount = 0;

            function traverseNodes(node) {
                if (node.nodeType === Node.TEXT_NODE) {
                let nextCharCount = charCount + node.length;
                if (nextCharCount >= pos) {
                    range.setStart(node, pos - charCount);
                    range.collapse(true);
                    return true;
                }
                charCount = nextCharCount;
                } else {
                for (let child of node.childNodes) {
                    if (traverseNodes(child)) return true;
                }
                }
                return false;
            }

            traverseNodes(node);
            selection.removeAllRanges();
            selection.addRange(range);
        },

    },
    computed: {
        computedPosition() {
            const position = this.position
            if (position.onTop) {
                return { bottom: position.bottom + 'px', left: position.left + 'px', visibility: position.visibility }
            } else {
                return { top: position.top + 'px', left: position.left + 'px', visibility: position.visibility }
            }
        },
    }
}
</script>

<style>
.dropdown {
    position: absolute;
    z-index: 9999;
    background: white;
    border: 3px solid rgba(255,150,30,.75); 
    list-style: none;
    padding: 0;
    margin: 0;

    display: flex;
}
.dropdown.onTop {
    box-shadow: 4px -4px 8px rgba(0, 0, 0, 0.2);
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
    border-bottom-style: dotted;
    flex-direction: column-reverse;
}

.dropdown.onBottom {
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-top-style: dotted;
    flex-direction: column;
}

.dropdown-commands {
    display: flex;
}

.dropdown ul {
    padding: 0;
    margin: 0
}

.dropdown li {
    padding: 8px;
    cursor: pointer
}

.dropdown li:hover {
    background-color: #f0f0f0
}

ul .wj-state-match {
    background: #ff0;
    color: #000;
    font-weight: 700;
}

.key-words {
    color: #000;
    font-weight: 900;
}

.commandPanel-row, .commandPanel-cell {
    display: flex;
    align-items: center;
}
.commandPanel-title {
    font-weight: bold;
    padding-left: 5px;
}

</style>

<style scoped>
.list-container {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
}

.list-item {
    display: flex;
    justify-content: space-between;
    padding: 8px 12px;
    border-bottom: 1px solid #e0e0e0;
    cursor: pointer;
    transition: background 0.2s ease-in-out;
}

.list-item:hover {
    background: #f5f5f5;
}

.title {
    flex-grow: 1;
    text-align: left;
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.id {
    text-align: right;
    color: #888;
    font-size: 0.9rem;
    min-width: 80px;
}

.editorHTML {
    width: 100%;
    min-height: 100px;
    padding: 10px;
    border: 1px solid #ccc;
    background: white;
    font-size: 16px;
}
.editorHTML:focus {
    outline: none;
    border: 2px solid rgba(255, 150, 30, .75);
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
}

.value {
    width: 100%;
    min-height: 120px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    background: #f2f2f2;
    font-family: "Courier New", monospace;
    line-height: 1.5;
    color: #333;
    resize: vertical;
}
.value:focus {
    outline: none;
    border: 2px solid rgba(255, 150, 30, .75);
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
}
textarea.long-text {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
</style>