<template>
    <div
        :class="[
            WIDGET.class,
            {
                'widget-hidden': WIDGET.hidden,
                'widget-visible': !WIDGET.hidden,
                'widget-docs': WIDGET.styleWidget?.dock,
                'drag-source': WIDGET.isDragging,
                'drag-over': WIDGET.isDragOver,
            },
        ]"
        :style="WIDGET.style"

        draggable="true"
        @dragstart="dragStart($event)"
        @dragover="dragOver($event)"
        @dragleave="dragLeave"
        @drop="dragDrop($event)"
        @dragend="dragEnd($event)"

        @focusin="activateWidget(false, false)"
        @click="clickWidget"
    >
        <div
            :class="{
                'widget-container': true,
                'widget-loading': WIDGET.loading || WIDGET.firstBackendCheck,
                'widget-active': isActiveWidget,
                'widget-container-inactive': !isActiveWidget && isMainArea,
            }"
            v-wjContextMenu="'ContextWidgetMenuId'"
            v-wjTooltip="tooltip()"
        >
            <component
                v-for="attrM in commandPanelLeft"
                :key="attrM"
                :widget_id="widget_id" 
                :is="attrM.component"
                :attr_id="attrM.id"
                :initialAttr="attrM" 
                @mouseenter="onShow_commandPanel_topLeft"
                @mouseleave="onClose_commandPanel_topLeft"
            />
            
            <i :class="WIDGET.pi + ' pi-header pi-1'" v-if="WIDGET?.pi"></i>
            <!-- <i :class="WIDGET.pi2 + ' pi-header pi-2'" v-if="WIDGET?.pi2"></i> -->
            <i :class="WIDGET.pi3 + ' pi-header pi-3'" v-if="WIDGET?.pi3"></i>

            <span v-if="WIDGET.shortTitle" class="segment-title pi-header pi-1" @click="openSegmentList">{{ WIDGET.shortTitle }}</span>
            
            <input 
                ref="inputHeader"
                type="text"
                class="widget-header single-line-text"
                :id="'widget_title'+widget_id"
                v-model="WIDGET.title"
                :placeholder="WIDGET?.record?.doc?.['title-placeholder'] || 'Enter title'"
                autocomplete="off"
                @mousedown="header_mousedown"
                @click="activateWidget(true)"
                @dblclick="enableEditing"
                @keydown="handleKeydown"
                @blur="onTextChange"
                :readonly="!isEditingHeader"
            />
                <!-- :readonly="!WIDGET.title_editable" -->
            <span v-if="WIDGET.record?.params?.showArchived">, ARCHIVED</span>
            <span v-if="WIDGET.activityInfo">, {{ WIDGET.activityInfo }}</span>

            <!-- <div 
                class="widget-header single-line-text" 
                @mousedown="header_mousedown"
                @click="activateWidget(true)"
                @dblclick="onZoomClick" 
                :id="'widget_title'+widget_id"
                v-wjTooltip="tooltip()"
                :contenteditable="WIDGET.title_editable"
                @keydown="handleKeydown"
                @blur="onTextChange"
            >
                <span v-html="WIDGET.title || WIDGET.title0"></span>
                <span v-if="WIDGET.record?.params?.showArchived">, ARCHIVED</span>
                <span v-if="WIDGET.activityInfo">, {{ WIDGET.activityInfo }}</span>
            </div> -->


            <!-- {{WIDGET.record && WIDGET.record.title ? WIDGET.record.title : WIDGET.title}}{{WIDGET.record.doc.params && WIDGET.record.doc.params?.showArchived?', ARCHIVED':''}}{{WIDGET.activityInfo?`, ${WIDGET.activityInfo}`:''}} -->
            <div :class="{
                'buttons': true,
                'buttons-inactive': !isActiveWidget && isMainArea,
                'widget-container-buttons-max-inactive': !isActiveWidget && isMainArea && WIDGET?.Rect?.width > 500,
                'widget-container-buttons-min': (isActiveWidget || !isMainArea) && WIDGET?.Rect?.width <= 500,
                'widget-container-buttons-min-inactive': !isActiveWidget && isMainArea && WIDGET?.Rect?.width <= 500,
            }" >
                <component
                    v-for="attrM in commandPanel"
                    :key="attrM"
                    :widget_id="widget_id" 
                    :is="attrM.component"
                    :attr_id="attrM.id"
                    :initialAttr="attrM" 
                />

            </div>
            <div class="widget_menu mr-2"
                :class="{ 'buttons-inactive': !isActiveWidget && isMainArea }" 
            >
                <wj-menu id="actionMenuId" :displayMemberPath="'titleAction'" :subItemsPath="'attrs'" :showDropDownButton="false"
                    v-if="WIDGET && 'groupMenu' in WIDGET && 'actionMenu' in WIDGET.groupMenu"
                    :openOnHover="true" :isAnimated="false" :itemsSource="actionMenu" :initialized="initMenu" :itemClicked="menuNodeClicked"
                >
                    <i class="pi pi-bars pi-bold"></i>
                </wj-menu>
            </div>
        </div>

        <div v-if="WIDGET.loading || WIDGET.firstBackendCheck" class='widget-text-loading'></div>
        <div v-if="WIDGET?.notification?.visible" class="notification-widget" :class="{ 
            'notification-warning': WIDGET.notification.status=='warning',
            'notification-error': WIDGET.notification.status=='error', 
            'notification-critical': WIDGET.notification.status=='critical', 
        }">
            <span v-if="WIDGET.notification.header" class="notification-header">
                {{ store?.truncateText(WIDGET.notification.header, 100) }}
            </span>
            <br v-if="WIDGET.notification.header">
            {{ store?.truncateText(WIDGET.notification.text, 150) }}
            <!-- <div class="button ml-2 btnScale-hover" title="Close notification" @click="store.close_notification">
                <i class="pi pi-times"></i>
            </div> -->
        </div>
        
        <!-- content -->
        <div 
            :class="{
                'widget-content': true,
                'widget-content-inactive': false && !isActiveWidget && isMainArea,
            }"
            v-wjContextMenu="'cellMenuId'" >
            <slot :palette="palette" />
        </div>

        <!-- <wj-popup class="popover popup-tab" :initialized="initPopupTab" 
            :isResizable="true"
        >
            <div class="popover-content" v-if="WIDGET">
                <component v-for="attr in WIDGET?.attrs_popupTab"
                    :widget_id="widget_id" 
                    :key="attr.html_id"
                    :is="attr.component"
                    :attr_id="attr.id"
                />
            </div>
        </wj-popup> -->

        <div class="widget-commandPanel widget-commandPanel-top-left"
            :class="{ 'top-buttons-1': WIDGET?.record?._meta?.modified }"
            v-if="show_commandPanel_topLeft"
            @mouseenter="onShow_commandPanel_topLeft"
            @mouseleave="onClose_commandPanel_topLeft"
        >
            <div class="widget-commandPanel-1">
                <div class="widget-commandPanel-row" v-for="(row, rowIndex) in commandPanel_topLeft1" 
                    :key="'row-' + rowIndex" 
                >
                    <component v-for="attrM in row" 
                        :key="attrM.html_id" 
                        :widget_id="widget_id" 
                        :is="attrM.component" 
                        :attr_id="attrM.id" 
                        :initialAttr="attrM" 
                    />
                </div>
            </div>
            <div class="widget-commandPanel-2">
                <div class="widget-commandPanel-row" v-for="(row, rowIndex) in commandPanel_Scale" 
                    :key="'row-' + rowIndex" 
                >
                    <component v-for="attrM in row" 
                        :key="attrM.html_id" 
                        :widget_id="widget_id" 
                        :is="attrM.component" 
                        :attr_id="attrM.id" 
                        :initialAttr="attrM"
                        @mouseenter="mouseenter_btnScale(attrM)"
                        @mouseleave="mouseleave_btnScale(attrM)"
                    />
                </div>
            </div>
        </div>
        <div class="resizer-top-left" @mouseenter="onShow_commandPanel_topLeft" v-if="!show_commandPanel_topLeft"></div>

        <!-- <div class="widget-commandPanel widget-commandPanel-bottom-right widget-commandPanel-2" v-show="show_commandPanel_bottomRight" @mouseleave="onClose_commandPanel_bottomRight">
            <div class="widget-commandPanel-row" v-for="(row, rowIndex) in commandPanel_bottomRight" 
                :key="'row-' + rowIndex" 
            >
                <component v-for="attrM in row" 
                    :key="attrM.html_id" 
                    :widget_id="widget_id" 
                    :is="attrM.component" 
                    :attr_id="attrM.id" 
                    :initialAttr="attrM" 
                />
            </div>
            <div class="resizer-bottom-right-active"></div>
        </div>
        <div class="resizer-bottom-right" @mouseenter="onShow_commandPanel_bottomRight" v-show="!show_commandPanel_bottomRight"></div> -->

    </div>
</template>

<script>
import { useMainStore } from '@/stores/mainStore'
import AttrButton from './components_core/AttrButton.vue';
// import RemoveIcon from '../icons/RemoveIcon.vue';

export default {
    props: [
        'widget_id',
    ],
    data: () => ({ 
        WIDGET: {},
        commandPanel: [],
        actionMenu: [],

        commandPanelLeft: [],
        show_commandPanel_topLeft: false,
        commandPanel_topLeft1: [
            [
            //     { 'tooltip': 'Save', 'command': 'onSaveRecord__Click', 'v-if-meta000':'modified', 'params':{'closeWindow':false}, 'cssClass':'pi p-button-yellow mb-1 pi-save' }
            // ],[
                { 'tooltip': 'Reload', 'cssClass':'pi p-button-yellow mr-0 p-button-text0 pi-sync', 'command': 'executeWidgetMethod', 'method': 'reload', 'record_sendToBackend_exclude': ['_doc'], 'v-if':"WIDGET?.record?._meta?.modified && WIDGET?.record?.id" },
                { 'tooltip': 'Close Widget', 'cssClass':'pi btn-widget-close p-button-white mr-0 p-button-text0 pi-times', 'command': 'onCloseWindow__Click' },
            ]
        ],
        commandPanel_Scale: [
            [
            //     { 'tooltip': 'Minimize', 'cssClass':'pi p-button-white mr-0 p-button-text0  pi-window-minimize', 'command': 'onMinimizeWidget__Click' },
            //     // { 'tooltip': 'Normal', 'command': 'onScale__Click', 'params':{'cssClassW':'none', 'cssClassH':'none'} },
            //     // { 'tooltip': 'Zoom', 'command': 'onScale__Click', 'params':{'widthMin_rem':4, 'heightMin_rem':4} },
            //     { 'tooltip': 'Full screen', 'cssClass':'pi p-button-white mr-0 p-button-text0  pi-window-maximize', 'command': 'onFullscreen__Click' },
            // ],[
                { 'tooltip': 'Minimize', 'cssClass':'pi btn-widget-minimize p-button-white mr-0 p-button-text0  pi-minus', 'command': 'onMinimizeWidget__Click' },
                { 'tooltip': 'Full screen', 'cssClass':'pi btn-widget-maximaze p-button-white mr-0 p-button-text0  pi-arrow-up-right-and-arrow-down-left-from-center', 'command': 'onFullscreen__Click' },
                { 'tooltip': 'Close others', 'cssClass':'pi btn-widget-close p-button-white mr-0 p-button-text0  pi-bullseye', 'command': 'onCloseOthersWindow__Click' },
            ],[
                { 'tooltip': 'Copy to clipboard', 'cssClass':'pi btn-widget-minimize p-button-white mr-0 p-button-text0  pi-copy', 'command': 'externalLink', method: 'copyToClipboard' },
                { 'tooltip': 'Open record in new tab', 'cssClass':'pi btn-widget-minimize p-button-white mr-0 p-button-text0  pi-external-link', 'command': 'externalLink', method: 'openInNewTab' },
                { 'tooltip': 'Clone', 'command':'onCloneRecord__Click', 'params':{'clone':true}, 'cssClass':'pi btn-widget-minimize p-button-white mr-0 p-button-text0 pi-clone'},
            ],[
                { 'tooltip': 'Scale widh 1, height 1', 'cssClass':'pi btn-widget-size p-button-white mr-0 p-button-text0 pi-expand btnScale', 'command': 'onScale__Click', 'params':{'widthMin_rem':1, 'heightMin_rem':1} },
                { 'tooltip': 'Scale widh 2, height 1', 'cssClass':'pi btn-widget-size p-button-white mr-0 p-button-text0 pi-expand btnScale', 'command': 'onScale__Click', 'params':{'widthMin_rem':2, 'heightMin_rem':1} },
                { 'tooltip': 'Scale widh 3, height 1', 'cssClass':'pi btn-widget-size p-button-white mr-0 p-button-text0 pi-expand btnScale', 'command': 'onScale__Click', 'params':{'widthMin_rem':3, 'heightMin_rem':1} },
                { 'tooltip': 'Scale widh 4, height 1', 'cssClass':'pi btn-widget-size p-button-white mr-0 p-button-text0 pi-expand btnScale', 'command': 'onScale__Click', 'params':{'widthMin_rem':4, 'heightMin_rem':1} },
                // { 'tooltip': 'Full screen', 'cssClass':'pi p-button-white mr-0 p-button-text0', 'command': 'onFullscreen__Click' },
            ],[
                // { 'tooltip': 'Minimize', 'cssClass':'pi p-button-white mr-0 p-button-text0', 'command': 'onMinimizeWidget__Click' },
                { 'tooltip': 'Scale widh 1, height 2', 'cssClass':'pi btn-widget-size p-button-white mr-0 p-button-text0 pi-expand btnScale', 'command': 'onScale__Click', 'params':{'widthMin_rem':1, 'heightMin_rem':2} },
                { 'tooltip': 'Scale widh 2, height 2', 'cssClass':'pi btn-widget-size p-button-white mr-0 p-button-text0 pi-expand btnScale', 'command': 'onScale__Click', 'params':{'widthMin_rem':2, 'heightMin_rem':2} },
                { 'tooltip': 'Scale widh 3, height 2', 'cssClass':'pi btn-widget-size p-button-white mr-0 p-button-text0 pi-expand btnScale', 'command': 'onScale__Click', 'params':{'widthMin_rem':3, 'heightMin_rem':2} },
                { 'tooltip': 'Scale widh 4, height 2', 'cssClass':'pi btn-widget-size p-button-white mr-0 p-button-text0 pi-expand btnScale', 'command': 'onScale__Click', 'params':{'widthMin_rem':4, 'heightMin_rem':2} },
            ]
        ],

        show_commandPanel_bottomRight: false,
        commandPanel_bottomRight: [
            [
                { 'title': '1', 'tooltip': 'Scale widh 1, height 1', 'cssClass':'pi p-button-white mr-0 p-button-text0 ', 'command': 'onScale__Click', 'params':{'widthMin_rem':1, 'heightMin_rem':1} },
                { 'title': '2', 'tooltip': 'Scale widh 2, height 1', 'cssClass':'pi p-button-white mr-0 p-button-text0 ', 'command': 'onScale__Click', 'params':{'widthMin_rem':2, 'heightMin_rem':1} },
                { 'title': '3', 'tooltip': 'Scale widh 3, height 1', 'cssClass':'pi p-button-white mr-0 p-button-text0 ', 'command': 'onScale__Click', 'params':{'widthMin_rem':3, 'heightMin_rem':1} },
                { 'title': '4', 'tooltip': 'Scale widh 4, height 1', 'cssClass':'pi p-button-white mr-0 p-button-text0 ', 'command': 'onScale__Click', 'params':{'widthMin_rem':4, 'heightMin_rem':1} },
            ],[
                { 'title': '5', 'tooltip': 'Scale widh 1, height 2', 'cssClass':'pi p-button-white mr-0 p-button-text0 ', 'command': 'onScale__Click', 'params':{'widthMin_rem':1, 'heightMin_rem':2} },
                { 'title': '6', 'tooltip': 'Scale widh 2, height 2', 'cssClass':'pi p-button-white mr-0 p-button-text0 ', 'command': 'onScale__Click', 'params':{'widthMin_rem':2, 'heightMin_rem':2} },
                { 'title': '7', 'tooltip': 'Scale widh 3, height 2', 'cssClass':'pi p-button-white mr-0 p-button-text0 ', 'command': 'onScale__Click', 'params':{'widthMin_rem':3, 'heightMin_rem':2} },
                { 'title': '8', 'tooltip': 'Scale widh 4, height 2', 'cssClass':'pi p-button-white mr-0 p-button-text0 ', 'command': 'onScale__Click', 'params':{'widthMin_rem':4, 'heightMin_rem':2} },
            ],[
                { 'tooltip': 'Minimize', 'cssClass':'pi p-button-white mr-0 p-button-text0  pi-window-minimize', 'command': 'onMinimizeWidget__Click' },
                // { 'tooltip': 'Normal', 'command': 'onScale__Click', 'params':{'cssClassW':'none', 'cssClassH':'none'} },
                // { 'tooltip': 'Zoom', 'command': 'onScale__Click', 'params':{'widthMin_rem':4, 'heightMin_rem':4} },
                { 'tooltip': 'Full screen', 'cssClass':'pi p-button-white mr-0 p-button-text0  pi-window-maximize', 'command': 'onFullscreen__Click' },
            ],
        ],

        isEditingHeader:false,
        isMainArea: true,
    }),
    // setup: () => ({ palette }),
    setup() {
        const store = useMainStore();
        const palette = store.palette;
        return {
            store,
            palette
        }
    },
    created() {
        // this.title = this.store.findWidget(this.widget_id).WIDGET.title
        // console.log('created Widget')
        const WIDGET = this.WIDGET = this.store.findWidget(this.widget_id).WIDGET
        // if (!('vueObj' in WIDGET)) {
        //     WIDGET.vueObj = this
        // }
            
        // this.store.widget_attr_set(this)
        WIDGET.attrs_vueObj.WIDGET = this
     
        this.set_commandPanel()

        this.PAGE = this.store.WORKSPACES[WIDGET.workspace].PAGES[WIDGET.page]
    
        // const scrollPosition = window.scrollY
        // this.$nextTick(() => {
        //     window.scrollTo(0, scrollPosition)
        // })

        this.commandPanel_topLeft1.forEach((panel, index) => {
            this.store.prepare_attrs(panel, `commandPanel1_${WIDGET.id}_${index}`)
        })
        this.commandPanel_Scale.forEach((panel, index) => {
            this.store.prepare_attrs(panel, `commandPanel2_${WIDGET.id}_${index}`)
        })
        this.commandPanel_bottomRight.forEach((panel, index) => {
            this.store.prepare_attrs(panel, `commandPanel3_${WIDGET.id}_${index}`)
        })
        this.mouseenter_btnScale()
    },
    mounted() {
        let vueObj = this; const store = this.store; let WIDGET = this.WIDGET
        this.store.widgetResize_afterTimer(vueObj)
        this.setObserver_widgetResize()
        store.Scale(vueObj, WIDGET)

        if ('actionMenu' in vueObj) vueObj.actionMenu.command = vueObj.menuCommand();

        vueObj.activateWidget()

        this.PAGE?.mainArea?.vueObj.checkWorkspaceScroll()
    },
    updated() {
        if (this.WIDGET?.startTime) {
            if (this.timerId) {
                clearTimeout(this.timerId)
            }
            const endTime = performance.now()
            this.timerId = setTimeout(() => {
                console.log()
                this.WIDGET.TERMINAL += `\n${( (endTime - this.WIDGET.startTime) / 1000).toFixed(3) }s rendering [[[[[[[[[[[]]]]]]]]]]]`
                this.WIDGET.startTime = null
                if (this.WIDGET.vueObj.content_Changed_attr_vueObj) {
                    this.WIDGET.vueObj.content_Changed_attr_vueObj('TERMINAL', {fold:true})
                }
            }, 2000)

        }
    },
    unmounted() {
        this.PAGE?.mainArea?.vueObj.checkWorkspaceScroll()
    },
    methods: {
        activateWidget(is_header=false, run_scrollInWorkspace=true) {
            // if (is_header || this.WIDGET.component !== 'WidgetWorkspace') {
                this.store.widget_activate(this.WIDGET, run_scrollInWorkspace)
            // }
        },
        clickWidget() {
            if (this.WIDGET.styleWidget?.inDock) {
                this.store.widget_activate(this.WIDGET)
            }
        },
        initMenu(menu) {
            this[menu.hostElement.id] = menu;
        },
        menuCommand(param) {
            let this_ = this
            return {
                executeStoreMethod: function(param){
                    let vueObj = this_.WIDGET.vueObj
                    vueObj.store.executeStoreMethod(vueObj, param)
                },
                canExecuteCommand: function(param){
                    return true;
                }
            }
        },
        menuNodeClicked(menu) {
            let actionMenu = menu.itemsSource[menu.selectedIndex]
            let vueObj = this.WIDGET.vueObj
            this.store.executeStoreMethod(vueObj, actionMenu)
        },
        onClick(actionMenu) {
            let vueObj = this.WIDGET.vueObj
            vueObj.store.executeStoreMethod(vueObj, actionMenu)
        },
        onZoomClick() {
            if (!this.WIDGET.title_editable) {
                let vueObj = this.WIDGET.vueObj
                if (this.WIDGET.record.doc.widthMin_rem === 1) {
                    vueObj.store.executeStoreMethod(vueObj, {'command': 'onScale__Click', 'params':{'widthMin_rem':4, 'heightMin_rem':4}})
                } else {
                    vueObj.store.executeStoreMethod(vueObj, {'command': 'onScale__Click', 'params':{'widthMin_rem':1, 'heightMin_rem':1}})
                }
                this.mouseenter_btnScale()
            }
        },
        handleKeydown(e) {
            if (e.key === 'Enter') {
                e.preventDefault()

                // e.target.blur()
                this.onTextChange(e)
            }
        },
        enableEditing() { // onZoomClick
            this.isEditingHeader = true
            this.$nextTick(() => {
                const inputEl = this.$refs.inputHeader
                inputEl.focus()
                inputEl.select()
            })
        },
        onTextChange(e) {
            if (this.WIDGET.record.title !== this.WIDGET.title) {
                if (this.WIDGET.widget === 'widget-search') {
                    this.WIDGET.record.title = this.WIDGET.title
                } else {
                    this.store.set_value(this.WIDGET.record, 'title', this.WIDGET.title)
                }

                if (this.WIDGET.page === this.store.activeWORKSPACE?.activePAGE?.page) {
                    this.WIDGET.vueObj.content_Changed() // for update value in other places

                    if (this.WIDGET.action_afterChange_title) {
                        this.store.executeStoreMethod(this.WIDGET.vueObj, this.WIDGET.action_afterChange_title)
                    }
                }
            }
            this.isEditingHeader = false
        },
        setObserver_widgetResize() {
            const vueObj = this,
            elementToObserve = document.querySelector(`#WIDGET${vueObj.widget_id}`)
            if (elementToObserve) {
                const resizeObserver = new ResizeObserver(entries => {
                    vueObj.store.widgetResize(vueObj, 120)
                })
                resizeObserver.observe(elementToObserve)
            }
        },
        set_commandPanel(eval_params={}) {
            if ('groupMenu' in this.WIDGET && 'commandPanel' in this.WIDGET.groupMenu) {
                // console.log('set_commandPanel')
                this.commandPanelLeft = []
                this.commandPanel = []
                
                for (let command of this.WIDGET.groupMenu.commandPanel) {
                    // const command = command_, v_if = command_['v-if'] // { 'v-if': v_if, ...command } = command_
                    // command.visible = this.store.eval_value(this.WIDGET, v_if, eval_params)
                    // if (command.visible) {
                        command.cssClass2 = ' mr-1 '
                        if (command.isWidgetLeftPanelCommand) {
                            this.commandPanelLeft.push(command)
                        } else {
                            this.commandPanel.push(command)
                        }
                    // }
                }

                this.actionMenu = this.store.actionMenu_from_commandPanel(this.commandPanel)
                for (let command_ of this.WIDGET.groupMenu.actionMenu) {
                    const command = command_, v_if = command_['v-if'] // { 'v-if': v_if, ...command } = command_
                    command.visible = this.store.eval_value(this.WIDGET, v_if, eval_params)
                    const lastAddedAttr = this.actionMenu.length > 0 ? this.actionMenu[this.actionMenu.length - 1] : null
                    if (command.visible && (!lastAddedAttr || lastAddedAttr.title !== command.title)) {
                        this.actionMenu.push(this.store.prepare_actionMenu(command))
                    }
                }
            }
        },
        tooltip() {
            let params = {},
            WIDGET = this.WIDGET
            
            params['id'] = WIDGET.record?.id || WIDGET.record?.id
            params['segment'] = WIDGET.record?.segment || WIDGET.record?.segment
            
            if (this.isReadOnly) { 
                params['isReadOnly'] = 'true'
            }
            if (WIDGET.record?.meta?.predefined) {
                params['Predefined'] = `<input type="checkbox" checked disabled>`

                params[`Non Updatable`] = this.store.attr_html(this.WIDGET, 'Widget', 'noupdate', WIDGET.record.meta.noupdate)
            }
                            
            return this.store.tooltip_html(params, WIDGET.tooltip||'')
        },
        initPopupTab(popup) {
            this.popupTab = popup
        },
        
        // ------------- resize ----------------------------------------
        onShow_commandPanel_topLeft(event, vueEvent) {
            if (this.timerId_onShow_commandPanel_topLeft) { clearTimeout(this.timerId_onShow_commandPanel_topLeft) }
            if (this.timerId_onClose_commandPanel_topLeft) { clearTimeout(this.timerId_onClose_commandPanel_topLeft) }
            console.log('show ' + !!vueEvent)
            this.timerId_onShow_commandPanel_topLeft = setTimeout(() => {
                if (this.store.mouse_isInside(event.target)) {
                    if (!this.show_commandPanel_topLeft) {
                        this.mouseenter_btnScale()
                        this.show_commandPanel_topLeft = true
                        this.timerId_onClose_commandPanel_topLeft = setTimeout(() => {
                            this.show_commandPanel_topLeft = false
                        }, 200)
                    }
                } else {
                    this.show_commandPanel_topLeft = false
                }
            }, 200)
        },
        onClose_commandPanel_topLeft(event) {
            if (this.timerId_onShow_commandPanel_topLeft) { clearTimeout(this.timerId_onShow_commandPanel_topLeft) }
            if (this.timerId_onClose_commandPanel_topLeft) { clearTimeout(this.timerId_onClose_commandPanel_topLeft) }
            console.log('X')
            this.timerId_onClose_commandPanel_topLeft = setTimeout(() => {
                this.show_commandPanel_topLeft = false
            }, 200)
        },
        onShow_commandPanel_bottomRight(event) {
            this.show_commandPanel_bottomRight = true
            // this.isManuallyResizing = true

            // this.startX = event.clientX;
            // this.startY = event.clientY;
            // this.scaling = true;

            // window.addEventListener("mouseenter", this.onResize);
            // window.addEventListener("mouseup", this.stopResize);
        },
        onResize(event) {
            // const dx = event.clientX - this.startX;
            // const dy = event.clientY - this.startY;

            // this.scaleX = (this.initialWidth + dx) / this.initialWidth;
            // this.scaleY = (this.initialHeight + dy) / this.initialHeight;
        },
        onClose_commandPanel_bottomRight(event) {
            this.show_commandPanel_bottomRight = false
            // if (this.isManuallyResizing) {
            //     this.isManuallyResizing = false
            // }

            // const dx = event.clientX - this.startX;
            // const dy = event.clientY - this.startY;

            // this.finalWidth = this.initialWidth + dx;
            // this.finalHeight = this.initialHeight + dy;
            // this.initialWidth = this.finalWidth;
            // this.initialHeight = this.finalHeight;

            // this.scaleX = 1;
            // this.scaleY = 1;
            // this.scaling = false;

            // window.removeEventListener("mouseenter", this.onResize);
            // window.removeEventListener("mouseup", this.stopResize);
        },

        // ------------- drag&drop ----------------------------------------
        header_mousedown(e) {
            this.is_header_mousedown = e.currentTarget.closest('.WIDGET')
        },
        dragStart(e) {
            if (this.is_header_mousedown) {
                let pSource = this.store.params_dragSource = {
                    WIDGET: this.WIDGET,
                    vueObj: this,
                    type: 'widget',
                }

                pSource.text = pSource.WIDGET.title
                e.dataTransfer.setData('text/plain', pSource.text)
                e.dataTransfer.setDragImage(this.get_dragImage(pSource), 10, 100)
    
                pSource.WIDGET.isDragging = true
                e.dataTransfer.effectAllowed = 'move'
            } else {
                e.preventDefault()
            }
        },
        get_dragImage(pSource) {
            const dragImage = pSource.dragImage = document.createElement('div')
            dragImage.className = 'drag-box'
            
            dragImage.innerHTML = `<b class='drag-header'>${pSource.text}</b>`

            if (pSource.WIDGET.pi) {
                dragImage.innerHTML = `<i class="${pSource.WIDGET.pi} pi-header drag-header"></i>` + dragImage.innerHTML
            }

            document.body.appendChild(dragImage)

            return dragImage
        },
        dragOver(e) {
            let pSource = this.store.params_dragSource
            if (!(pSource?.type === 'widget')) {
                return
            }

            let pTarget = this.store.params_dragTarget || {},
            pOver = {
                WIDGET: this.WIDGET,
                vueObj: this,
                type: 'widget',
            }

            if (pTarget?.WIDGET && pTarget.WIDGET !== pOver.WIDGET) {
                pTarget.WIDGET.isDragOver = false
                pTarget = this.store.params_dragTarget = {}
                console.log(`remoute drag-over-widget`)
            }

            e.preventDefault()
            if (pSource.WIDGET !== pOver.WIDGET && pTarget.WIDGET !== pOver.WIDGET) {
                e.dataTransfer.dropEffect = 'move'
                
                pTarget = this.store.params_dragTarget = pOver
                pTarget.WIDGET.isDragOver = true
                console.log(`add drag-over-widget`)
            }
        },
        dragDrop(e) {
            let pSource = this.store.params_dragSource
            if (!(pSource?.type === 'widget')) {
                return
            }

            let pTarget = this.store.params_dragTarget || {}

            if (pSource.WIDGET && pTarget.WIDGET) {
                const store = this.store,
                source = store.findWidget(pSource.WIDGET.id),
                target = store.findWidget(pTarget.WIDGET.id)
    
                if (store.swapOnDragAndDrop) {
                    store.WORKSPACES[source.workspace].PAGES[source.page].WIDGETS.splice(source.ind, 1, target.WIDGET)
                    store.WORKSPACES[target.workspace].PAGES[target.page].WIDGETS.splice(target.ind, 1, source.WIDGET)
                } else {
                    store.WORKSPACES[source.workspace].PAGES[source.page].WIDGETS.splice(source.ind, 1)
                    store.WORKSPACES[target.workspace].PAGES[target.page].WIDGETS.splice(target.ind, 0, source.WIDGET)
                }

                e.preventDefault()

                // // isMapped
                // if (this.PAGE.WIDGET_transfer) {
                //     this.store.set_isMappedWidgets(pSource.WIDGET, 1000)
                // }
            }
        },
        dragLeave() {
            let pTarget = this.store.params_dragTarget || {}
            if (!(pTarget?.type === 'widget')) {
                return
            }

            if (pTarget.WIDGET) {
                pTarget.WIDGET.isDragOver = false
            }
            this.store.params_dragTarget = {}
        },
        dragEnd() {
            let pSource = this.store.params_dragSource
            if (!(pSource?.type === 'widget')) {
                return
            }

            let pTarget = this.store.params_dragTarget || {}
            
            // source
            pSource.WIDGET.isDragging = false
            pSource.vueObj.is_header_mousedown = false
            
            if (pTarget.WIDGET) {
                pTarget.WIDGET.isDragOver = false
            }
            
            document.body.removeChild(pSource.dragImage)
            this.store.params_dragSource = {}
            this.store.params_dragTarget = {}
        },

        mouseenter_btnScale(attrM0) {
            if (this.timerId_mouseenter_btnScale) {
                clearTimeout(this.timerId_mouseenter_btnScale)
            }
            this.timerId_mouseenter_btnScale = setTimeout(() => {
                this.commandPanel_Scale.forEach((panel, index) => {
                    panel.forEach((attrM, index) => {
                        if ((attrM.params?.widthMin_rem && attrM0?.params?.widthMin_rem && attrM.params.widthMin_rem <= attrM0.params.widthMin_rem)
                        && (attrM.params?.heightMin_rem && attrM0?.params?.heightMin_rem && attrM.params.heightMin_rem <= attrM0.params.heightMin_rem)) {
                            if (!attrM.cssClass.includes('btnHover')) attrM.cssClass += ' btnHover'
                        } else {
                            if (attrM.cssClass.includes('btnHover')) attrM.cssClass = attrM.cssClass.replace(' btnHover','')
                        }

                        if ((attrM.params?.widthMin_rem && attrM.params.widthMin_rem <= (this.WIDGET.record?.doc?.widthMin_rem || 1))
                        && (attrM.params?.heightMin_rem && attrM.params.heightMin_rem <= (this.WIDGET.record?.doc?.heightMin_rem || 1))) {
                            if (!attrM.cssClass.includes('btnYellow')) attrM.cssClass += ' btnYellow'
                        } else {
                            if (attrM.cssClass.includes('btnYellow')) attrM.cssClass = attrM.cssClass.replace(' btnYellow','')
                        }
                    })
                })
            }, 100)
        },
        mouseleave_btnScale(attrM) {
            console.log(2)
        },

        openSegmentList() {
            this.store.executeStoreMethod(this.WIDGET.vueObj, { 'command': 'onShowInList__Click' })
        },
    },
    computed: {
        isReadOnly() {
            return this.WIDGET.isReadOnly||false // TODO WIDGET.isReadOnly
        },
        isActiveWidget() {
            return this.store.activeWIDGET === this.WIDGET
        },
        // isMainArea() {
        //     return this.WIDGET.area === 'main-area'
        // },
    },
    watch: {
        // 'WIDGET.record._meta.modified'(newVal, oldVal) {
        //     console.log(12)
        // },
        // loading(new_loading, old_loading) {
        //     console.log('change loading')
        //     $('#WIDGET'+this.widget_id)[0].classList.toggle("header_loading")
        // }
    },
}
</script>

<style>
.widget-header {
    all: unset;
}
.header_loading {
    background-color: lightyellow;
}
.widget-active {
    color:#647eff;
    font-weight: bold;
}
.widget-loading {
    animation-name: animation-loading-WIDGET;
    animation-duration: 7s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    /* background-color: #42d3926d; */
}
.widget-container-inactive {
    background: darkgray;
    color:white
}
.widget-content-inactive::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(169, 169, 169, 0.016);
    z-index: 2;
    pointer-events: none;
}
.widget-content-inactive {
    filter: grayscale(50%);
    /* opacity: 0.5; */
}
.widget-text-loading {
    position: absolute;
    margin-left: inherit;
}
.widget-text-loading:after {
    position: absolute;

    animation-name: animation-loading-text;
    animation-duration: 7s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    content: "Loading";

    font-family: helvetica, sans-serif;
    font-weight: bold;
    font-size: smaller;
    position: absolute;
    margin-left: 10px;
}
@keyframes animation-loading-text {
    0% { content: "Loading"; color: #647eff69; }
    15% { content: "Loading."; }
    30% { content: "Loading.."; }
    45% { content: "Loading..."; color: #42d3926d; }
    55% { content: "Loading"; }
    70% { content: "Loading."; }
    85% { content: "Loading.."; }
    100% { content: "Loading..."; color: #647eff69; }
}
@keyframes animation-loading-WIDGET {
    0% { background-color: #42d3926d; }
    45% { background-color: #647eff69; }
    100% { background-color: #42d3926d; }
}
.wj-control {
    border: none;
    color: var(--text-color);
}
.wj-dropdown {
    border: dotted lightgray;
}
.widget_menu .wj-dropdown {
    border: none;
}
.grid-menu .wj-dropdown {
    border: none;
}
/* .wj-control .wj-template {
    display: none !important;
} */
.my_right {
    margin: 0 -0.7rem 0 0;
}
.buttons {
    color: lightslategray;
}
.buttons-inactive {
    color: white;
}
.buttons-inactive .wj-control {
    color: white;
}
.widget-container .wj-menu {
    background: transparent;
}
.widget-container {
    position: relative;
    min-height: 37px;
}
.widget-container-buttons-max-inactive{
    position: absolute;
    right: 30px;
    visibility: hidden;
    text-shadow:
            /* many shadows blur out the area around the text */
            0 0 1px #a9a9a9,
            0 0 2px #a9a9a9,
            0 0 3px #a9a9a9,
            0 0 4px #a9a9a9,
            0 0 5px #a9a9a9,
            0 0 6px #a9a9a9,
            0 0 7px #a9a9a9,
            0 0 8px #a9a9a9,
            0 0 9px #a9a9a9,
            0 0 10px #a9a9a9
}
.widget-container-buttons-min{
    position: absolute;
    right: 30px;
    visibility: hidden;
    text-shadow:
            /* many shadows blur out the area around the text */
            0 0 1px white,
            0 0 2px white,
            0 0 3px white,
            0 0 4px white,
            0 0 5px white,
            0 0 6px white,
            0 0 7px white,
            0 0 8px white,
            0 0 9px white,
            0 0 10px white
}
.widget-container-buttons-min-inactive{
    position: absolute;
    right: 30px;
    visibility: hidden;
    text-shadow:
            /* many shadows blur out the area around the text */
            0 0 1px #a9a9a9,
            0 0 2px #a9a9a9,
            0 0 3px #a9a9a9,
            0 0 4px #a9a9a9,
            0 0 5px #a9a9a9,
            0 0 6px #a9a9a9,
            0 0 7px #a9a9a9,
            0 0 8px #a9a9a9,
            0 0 9px #a9a9a9,
            0 0 10px #a9a9a9
}
.WIDGET:hover .widget-container-buttons-max-inactive{
    visibility: visible;
}
.widget-container:hover .widget-container-buttons-min{
    visibility: visible;
}
.widget-container:hover .widget-container-buttons-min-inactive{
    visibility: visible;
}
.widget-container:hover .button-more{
    visibility: hidden;
}
.widget_menu {
	display: flex;
    align-items: center;
    z-index: 2;
    padding-left: 5px;
}
.pi-header {
    font-size: 1.3rem;
    font-weight: bold;
    padding-right: 0.3rem;
    padding-left: 0.3rem;
    opacity: 0.75;
}
.widget-hidden {
    /* visibility: hidden; !make flash to bottom */
    /* height: 0; */
    opacity: 0.2;
    /* transition: opacity 0.1s ease, visibility 0.1s ease; */
}

.widget-visible {
    visibility: visible;
    opacity: 1;
    /* transition: opacity 0.1s ease, visibility 0.1s ease; */
}
.pi-bold {
    font-size: 1.1rem;
    font-weight: bolder;
}
.doc-modified {
    background-color: yellow;
    color: #778899;
    border-radius: 6px;
    border: 1px solid #dddddd;
    padding: 3px 5px;
    font-weight: bold;
    margin-right: 5px;
}
.widget-commandPanel .pi.btn-widget-close {
    background: #ff5f57 !important;
    color: white !important;
    border-radius: 50%;
}

.widget-commandPanel .pi.btn-widget-minimize {
    background: #ffbc2d !important;
    color: white !important;
    border-radius: 50%;
}
.widget-commandPanel .pi.btn-widget-maximaze {
    background: #28cc42 !important;
    color: white !important;
    border-radius: 50%;
}
.widget-commandPanel .pi.btn-widget-size {
    color: white !important;
    border-radius: 0%;
}

.widget-commandPanel-top-left {
    position: absolute;
    top: 3px;
    left: 3px;
    z-index: 100;
    cursor: pointer;
}
.grid-commandPanel-top-right {
    position: absolute;
    right: 3px;
    z-index: 100;
    cursor: pointer;
}
.top-buttons-1 {
    top: 36px;
}
.widget-commandPanel-2 {
    border: 3px dotted lightslategray;
    border-radius: 0.5rem;
    background: rgba(255, 255, 255);
    /*background: #F6F9FC;*/
}
.widget-commandPanel-bottom-right {
    position: absolute;
    bottom: 3px;
    right: 3px;
    z-index: 100;
    cursor: nwse-resize;
}
.widget-commandPanel .pi {
    width: 30px;
    color: lightslategray !important;
    font-size: larger;
    font-weight: bolder;
}
.widget-commandPanel-row {
    display: flex;
    align-items: center;
}
.widget-commandPanel-1 {
    min-height: 28,5px;
    padding-bottom: 5px;
}
.resizer-bottom-right-active {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 20px;
    height: 20px;
    background: linear-gradient(
        135deg,
        transparent 50%,
        lightslategray 50%,
        lightslategray 60%,
        transparent 60%
      ),
      linear-gradient(
        135deg,
        transparent 70%,
        lightslategray 70%,
        lightslategray 80%,
        transparent 80%
      );
    background-size: 100% 100%;
    background-repeat: no-repeat;
    cursor: pointer;
    z-index: 99;
}
.resizer-bottom-right {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 20px;
    height: 20px;
    background: linear-gradient(
        135deg,
        transparent 50%,
        #999 50%,
        #999 60%,
        transparent 60%
      ),
      linear-gradient(
        135deg,
        transparent 70%,
        #999 70%,
        #999 80%,
        transparent 80%
      );
    background-size: 100% 100%;
    background-repeat: no-repeat;
    cursor: pointer;
    z-index: 99;
}
.resizer-top-left {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 30px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    cursor: pointer;
    z-index: 99;
}
.resizer-top-left-short {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    cursor: pointer;
    z-index: 99;
}
.button-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.button-lt {
    position: absolute;
    right: 0px;
    cursor: pointer;
}

.btnYellow {
    background-color: #ffe7cc !important;
}
.btnHover {
    background-color: #cce7ff !important;
}
.transformUp {
    transform: rotate(180deg);
    direction: rtl;
    writing-mode: vertical-lr;
}
.segment-title {
    font-stretch: ultra-condensed;
    color: white;
    background: #8ea0ff;
    border-radius: 3px;
    cursor: pointer;
}
.widget-container-inactive .segment-title {
    background: white;
    color: darkgray;
}
</style>
