<template>
    <Attr :widget_id="widget_id" :attr_id="attr_id">
        <div class="wj-labeled-input attr-text" v-wjTooltip="tooltip()">
            <wj-input-time 
                :class="'attr-element '+attr.cssClass"
                :id="attr.html_id"
                :isReadOnly="isReadOnly"
                :isRequired="false"
                :value="time"
                :valueChanged="attr_set"
            />
                <!-- :format="'p0'" -->
                <!-- :step=".05" -->
                <!-- :min="0" -->
                <!-- :max=".2" -->
            <label v-if="attr.title && !attr.hideTitle" :for="attr.html_id">{{ attr.title }}</label>
            <!-- <div class="wj-error" tabindex="-1">We do need your name...</div> -->
        </div>
    </Attr>
</template>

<script>
import { useMainStore } from '@/stores/mainStore'

export default {
    props: [
        'widget_id',
        'attr_id',
    ],
    data: () => ({ 
        WIDGET: {},
        time: 0,
        doc: {},
        binding: '',
    }),
    setup() {
        const store = useMainStore();
        return { store }
    },
    created() {
        // console.log(`created ${this.attr_id}`)
        this.WIDGET = this.store.findWidget(this.widget_id).WIDGET
        this.content_Changed()
    },
    methods: {
        content_Changed() {
            this.store.widget_attr_set(this)
        
            this.time = this.store.attr_get(this.WIDGET, this.attr)
        },
        attr_set(input) {
            this.store.attr_set(this.WIDGET, this.attr, input.value)
        },
        tooltip() {
            return this.store.tooltip_attr(this)
        },
    },
	computed: {
        isReadOnly() {
            return this.WIDGET.isReadOnly||this.attr.isReadOnly||this.attr.fieldMapping?.use||false
        },
        doc_binding() {
            return this.doc?.[this.binding]
        },
	},
    watch: {
        'doc_binding'(newValue, oldValue) {
            if (this.time !== newValue) {
                this.content_Changed()
            }
        }
    },
}
</script>

<style scoped>
.attr-text {
    width:-webkit-fill-available !important;
    max-width: 25rem !important;
}
</style>