<template>
    <Attr :widget_id="widget_id" :attr_id="attr_id">
        <div
            :id="attr.html_id"
            class="editorHTML"
            v-html="doc[binding]"
        />
        <label v-if="attr.title && !attr.hideTitle" :for="attr.html_id">{{ attr.title }}</label>
    </Attr>
</template>

<script>
import { useMainStore } from '@/stores/mainStore'

export default {
    props: [
        'widget_id',
        'attr_id',
    ],
    data: () => ({ 
        WIDGET: {},
        doc: {},
        binding: '',
    }),
    setup() {
        const store = useMainStore();
        return { store }
    },
    created() {
        // console.log(`created ${this.attr_id}`)
        this.WIDGET = this.store.findWidget(this.widget_id).WIDGET
        this.content_Changed()
    },
    methods: {
        content_Changed() {
            this.store.widget_attr_set(this)
        
            // this.text = this.store.attr_get(this.WIDGET, this.attr)
            const [doc, binding] = this.store.attr_get_link(this.WIDGET, this.attr)
            this.doc = doc, this.binding = binding
        },
        attr_set(input) {
            if (this.doc[this.binding] !== input.text) {
                this.store.attr_set(this.WIDGET, this.attr, input.text)
            }
        },
        tooltip() {
            return this.store.tooltip_attr(this)
        },
    },
	computed: {
        isReadOnly() {
            return this.WIDGET.isReadOnly||this.attr.isReadOnly||this.attr.fieldMapping?.use||false
        },
	},
}
</script>

<style scoped>
.attr-text {
    width:-webkit-fill-available !important;
}
</style>