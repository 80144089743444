<template>
	<div id="AppTopBar" class="layout-topbar">
		<button class="p-link p-link-topbar" 
			@click="onMenuToggle"
			@mouseenter="handleMouseEnter"
		>
			<i class="pi pi-bars"></i>
		</button>

		<button class="p-link layout-topbar-menu-button layout-topbar-button"
			v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', 
			leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true}">
			<i class="pi pi-ellipsis-v"></i>
		</button>

		<div>
			<span><div class="workspace-title" v-if="store.activeWORKSPACE">{{ titleTopBar }}</div></span>
		</div>

		<div class="workspace-menu">
			<wj-menu
				v-for="(menu, index) in menuTree"
				:key="index"
				:id="'menu_' + index"
				:header="menu.node?.valueStr"
				:displayMemberPath="'node.valueStr'"
				:subItemsPath="'attrs'"
				:showDropDownButton="false"
				:openOnHover="true"
				:isAnimated="false"
				:itemsSource="menu.attrs"
				:initialized="initMenu"
				:itemClicked="menuNodeClicked"
			/>
		</div>

		<div style="width: 21px;"/>
		<div class="widget_menu">
			<wj-menu id="ContextWidgetMenuId" :header="'Context Menu'" :displayMemberPath="'title'" :subItemsPath="'attrs'" :showDropDownButton="false" style="display:none"
				v-if="store.activeWIDGET && 'groupMenu' in store.activeWIDGET && 'contextWidgetMenu' in store.activeWIDGET.groupMenu"
				:openOnHover="true" :isAnimated="false" :itemsSource="store.activeWIDGET.groupMenu.contextWidgetMenu" :initialized="initMenu" :itemClicked="menuNodeClicked"
			/>

		</div>
		
		<ul class="layout-topbar-menu hidden lg:flex origin-top">
			<button type="button" class="p-link p-link-topbar" @click="globalSearch" v-wjTooltip="'Search (F3)'">
				<i class="pi pi-search "></i>
			</button>
			<button type="button" class="p-link p-link-topbar" @click="globalHistory" v-wjTooltip="'Recent activities'">
				<i class="pi pi-hourglass "></i>
			</button>
			<button type="button" class="p-link p-link-topbar" @click="globalLog" v-wjTooltip="'Log'">
				<i class="pi pi-history "></i>
			</button>
			<button type="button" class="p-link p-link-topbar" @click="copyPage" v-wjTooltip="'Copy page'">
				<i class="pi pi-copy "></i>
			</button>
			<!-- <button type="button" class="p-link p-link-topbar" @click="toggleDarkMode">
				<i :class="['pi', { 'pi-moon': $appState.darkTheme, 'pi-sun': !$appState.darkTheme }]"></i>
			</button> -->
			<li class="layout-flex-center">
				<i class="logo_pi pi pi-database"></i> <!--@click="frmEditAccountPopup.show(true)"-->
				<wj-menu id="menu_1" :header="store.user.database.title" :displayMemberPath="'title'" :subItemsPath="'attrs'" :showDropDownButton="false"
					v-if="store.user.database && 'title' in store.user.database"
					:openOnHover="true" :isAnimated="false" :itemsSource="store.database_attrs" :initialized="initMenu" :itemClicked="menuNodeClicked"
				/>
			</li>
			<li class="layout-flex-center">
				<i class="logo_pi pi pi-sitemap"></i>
				<!-- <img src="images/icon-process-model.png" height="20" class="mr-2"> filter: invert(1); -->
				<span><div class="process-model-title">{{ store.process_model_record?.title||'' }}</div></span>
			</li>
			<li class="layout-flex-center">
				<i class="logo_pi pi pi-user"></i>
				<wj-menu id="menu_1" :header="store.user.title" :displayMemberPath="'title'" :subItemsPath="'attrs'" :showDropDownButton="false"
					:openOnHover="true" :isAnimated="false" :itemsSource="user_menu" :initialized="initMenu" :itemClicked="menuNodeClicked"
				/>
			</li>
		</ul>

	</div>
</template>

<script>
// import 'bootstrap.css';
import { useMainStore } from './stores/mainStore'
import * as input from '@mescius/wijmo.input';

export default {
	data: () => ({ 
		frmEditAccountPopup: {},
		pw3: '',
		pw4: '',
	}),
	computed: {
		darkTheme() {
			return this.$appState.darkTheme;
		},
		menuTree() {
			return this.store.activeWORKSPACE?.activePAGE?.attrs||[]
		},
		titleTopBar() {
			const titlePage = Object.keys(this.store.activeWORKSPACE?.PAGES||'').length > 1 ? ` / ${this.store.activeWORKSPACE.activePAGE?.title||this.store.activeWORKSPACE.activePAGE?.tooltip||''}` : ''
			const titleWorkspace = this.store.activeWORKSPACE?.titleTopBar||this.store.activeWORKSPACE?.title
			return titleWorkspace + titlePage
		},
		user_menu() {
            return [
                { 'title': '<span class="pi pi-wrench"></span> My profile', 'command': 'onOpenMyProfile__Click' },
                { 'title': this.$appState.darkTheme ? '<span class="pi pi-sun"></span> Sun theme' : '<span class="pi pi-moon"></span> Dark theme', 'command': 'toggleDarkMode' },
                { 'title': this.store?.showTooltip ? 'Hide tooltips' : 'Show tooltips', 'command': 'showTooltip' },
                { 'title': this.store?.showSQL ? 'Hide SQL query texts' : 'Show SQL query texts', 'command': 'showSQL' },
                { 'title': '<span class="pi pi-eject"></span> Log out', 'command': 'onLogOut__Click' },
            ]
        },
	},
    setup() {
        const store = useMainStore();
        return {
			// workspace,
            store,
        }
    },
    created() {
        this.store.AppTopbar = this
    },
	mounted() {
		const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
	    this.$appState.darkTheme = prefersDark.matches;
		
		prefersDark.addEventListener('change', this.updateSystemTheme);
	},
	// beforeDestroy() {
	// 	const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
	// 	prefersDark.removeEventListener('change', this.updateSystemTheme);
	// },
    methods: {
        handleMouseEnter(event) {
            this.$emit('menu-toggle', event);
        },
        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
		onTopbarMenuToggle(event) {
            this.$emit('topbar-menu-toggle', event);
        },
		topbarImage() {
			return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo-dark.svg';
		},
		menuInitialized(menu){
			// this.menu = menu;
		},
		initMenu(menu) {
			this[menu.hostElement.id] = menu;
		},
		// menuCommand(param) {
			// 	let this_ = this
			// 	return {
				// 		executeStoreMethod: function(param){
					// 			let vueObj = this_.store.activeWIDGET.vueObj
					// 			vueObj.store.executeStoreMethod(vueObj, param)
					// 		},
		// 		canExecuteCommand: function(param){
			// 			return true;
			// 		}
			// 	}
		// },
		menuNodeClicked(menu) {
			let action = null
			if (menu.selectedIndex==-1 && this.store.activeWORKSPACE.groupMenu) {
				action = this.menuTree[menu.hostElement.id.slice(-1)]
			} else {
				action = menu.itemsSource[menu.selectedIndex]
			}
			action = {...action}
			action.command = action.command || 'widget_open'
			action.widget = action.widget || action.node?.value
			let vueObj = this
			if (this.store.activeWIDGET) {
				vueObj = this.store.activeWIDGET.vueObj 
			}
			this.store.executeStoreMethod(vueObj, action)
		},
		onShowSettings__Click(event) {
			let vueObj = this
			if (this.store.activeWIDGET) {
				vueObj = this.store.activeWIDGET.vueObj 
			}
			this.store.executeStoreMethod(vueObj, {command: "onShowSettings__Click"})
		},
		onClick: function(action) {
			let vueObj = this
			if (this.store.activeWIDGET) {
				vueObj = this.store.activeWIDGET.vueObj 
			}
			vueObj.store.executeStoreMethod(vueObj, action)
		},
		initWidget: function(popup){
			this[popup.hostElement.id] = popup;
		},
		onSubmit: function(popup){
			alert('WIDGET submitted');
			popup.hide();
		},
		globalSearch() {
			this.store.globalSearch.show()
			// 	this.store.executeStoreMethod(this, {'action_name':'action-front-search', 'command':'widget_open', 'widget':'widget-search', workspace: 'workspace-store'})
		},
		globalHistory() {
			this.store.globalHistory.show()
		},
		globalLog() {
			this.store.globalLog.show()
		},
		copyPage() {
			this.store.executeStoreMethod(this, { 'tooltip': 'Copy to clipboard', 'cssClass':'pi btn-widget-minimize p-button-white mr-0 p-button-text0  pi-copy', 'command': 'externalLink', method: 'copyToClipboard', externalLinkPage:true })
		},
		updateSystemTheme(e) {
			this.$appState.darkTheme = e.matches;
		},  
	},
	watch: {
		pw4: function(){
			this.cnfrmPswdFrmEditValid = this.pw4 !== this.pw3 ? false : true;
		}
	},
	components: {
	},
}
</script>

<style scoped>
* {
  --surface-card:lightslategray;
  --text-color: rgba(255, 255, 255, 0.87);
  --text-color-secondary: rgba(255, 255, 255, 0.6);
}
/* * {
	color: rgba(255, 255, 255, 0.6);
}
.topbar-menu {
	color: white !important;
} */
.logo {
	background: -webkit-linear-gradient(315deg,#42d392 25%,#647eff);
	-webkit-background-clip:text;
	-webkit-text-fill-color:transparent;
}
.workspace-title {
	font-size: 1.5rem;
	font-weight: 500;
	padding: 0 25px 0 5px;
	color: var(--text-color-secondary);
}
.process-model-title {
    padding: 0 5px;
    color: var(--text-color);
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.logo3 {
	font-size: 0.875rem;
	font-style: normal;
	/* font-weight: 500; */
	/* padding: 0 25px 0 5px; */
	color: var(--text-color-secondary);
}
.logo_pi {
	font-size: 1.5rem;
	color: var(--text-color-secondary);
	margin-left: 0rem !important;
}
.layout-flex-center {
	display: flex;
	align-items: center;
}
.widget_modal {
	background: transparent !important;
}
.wj-control {
  background: var(--surface-card);
  color: var(--text-color);
  border: none;
}
.wj-cell {
  background: var(--surface-card);
  color: var(--text-color);
}
.wj-cell.wj-alt {
  background: var(--surface-card);
}
.wj-cell.wj-header {
  background: var(--surface-card);
  color: var(--text-color);
}
/* .widget_menu {
	transition: all 250ms;
	opacity: 0;
}
.widget_menu:hover {
	opacity: 1;
} */
.p-link-topbar {
    margin: 0;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	position: relative;
	color: var(--text-color-secondary);
	border-radius: 50%;
	width: 3rem;
	height: 3rem;
	cursor: pointer;
	transition: background-color 0.2s;

    padding: 0;
    list-style: none;
;
}
.p-link-topbar:hover {
	color: var(--text-color);
}
.p-link-topbar i {
    font-size: 1.5rem;
}
</style>