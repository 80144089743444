<template>
    <Attr :widget_id="widget_id" :attr_id="attr_id">
        <label class="label-sheet" v-if="attr.title && (attr.showTitle !== undefined ? attr.showTitle : true)" :for="attr.html_id">{{ attr.title }}</label>
        <div class="attr-codemirror"
            v-wjTooltip="attr.tooltip"
            v-wjContextMenu="'ContextWidgetMenuId'"
        >
            <Codemirror
                :id="attr.html_id"
                v-model:value="content_str"
                v-visible="handleVisibility"
                :options="cmOptions"
                @change="attr_set"
                @ready="editorReady"
                @keydown="handleKeydown"
            />
        </div>
    </Attr>
</template>

<script>
import { useMainStore } from '@/stores/mainStore'

import Codemirror from "codemirror-editor-vue3";
import "codemirror/mode/javascript/javascript.js";
import "codemirror/mode/python/python.js";
import "codemirror/mode/sql/sql.js";
import "codemirror/theme/dracula.css";
import "codemirror/addon/fold/foldcode";
import "codemirror/addon/fold/foldgutter";
import "codemirror/addon/fold/brace-fold";
import "codemirror/addon/fold/foldgutter.css";
import "codemirror/theme/neo.css";

export default {
    props: [
        'widget_id',
        'attr_id',
    ],
    emits: ['keydown'],
    data: () => ({ 
        WIDGET: {},
        content_str: '',
        refresh_editor: true,
        cmOptions: {
            mode: "python", // Language mode
            theme: "neo", // Theme
            lineNumbers: true, // Show line number
            smartIndent: true, // Smart indent
            indentUnit: 4, // The smart indent unit is 2 spaces in length
            foldGutter: true, // Code folding
            styleActiveLine: true, // Display the style of the selected row
            // fontsize: "small",
        },
    }),
    setup() {
        const store = useMainStore();
        return { store }
    },
    created() {
        // console.log(`created ${this.attr_id}`)
        this.WIDGET = this.store.findWidget(this.widget_id).WIDGET
        this.content_Changed()
    },
    methods: {
        content_Changed(actionMenu) {
            this.store.widget_attr_set(this)
            if (this.attr.cmOptions) {
                this.cmOptions = this.attr.cmOptions
            }
        
            const new_content_str = this.store.attr_get(this.WIDGET, this.attr)
            if (this.content_str !== new_content_str) {
                this.content_str = this.store.attr_get(this.WIDGET, this.attr)||''
                this.refresh_editor = true
            }
            if (this.editor && this.refresh_editor) {
                this.refresh_editor = false
                this.editor.setValue(this.content_str || '')
                this.editor.refresh()

                // fold all lines to the first level
                if (actionMenu?.fold) {
                    const editor = this.editor,
                        vueObj = this

                    // setTimeout(() => {
                        this.editor.operation(() => {
                            for (let i = 0; i < this.editor.lineCount(); i++) {
                                // this.editor.foldCode(CodeMirror.Pos(i, 0));
                                this.editor.foldCode(CodeMirror.Pos(i, 0), null, "fold")
                                // if (vueObj.isLineFolded(this.editor, i)) {
                                //     console.log(`Line ${i} is folded`);
                                // }
                            }
                        })
                    // }, 5)
                }                 
            }
        },
        isLineFolded(editor, line) {
            let info = editor.lineInfo(line);
            return info.gutterMarkers && info.gutterMarkers["CodeMirror-foldgutter"];
        },
        attr_set(editor) {
            this.store.attr_set(this.WIDGET, this.attr, this.content_str)
        },
        editorReady(editor) {
            this.editor = editor
        },
        handleVisibility(isVisible) {
            this.isVisible = isVisible
            if (isVisible && this.editor) {
                this.editor.refresh()
            }
        },
        handleKeydown(e) {
            if (e.key === 'Enter' && !e.shiftKey && this.attr.afterEnter) {
                // e.preventDefault()
                this.store.executeStoreMethod(this.WIDGET.vueObj, this.attr.afterEnter)
                this.content_str = this.content_str.trim()
            }
        },
    },
    components: {
      Codemirror,
    },
}
</script>

<style scoped>
.attr-codemirrort {
    width:-webkit-fill-available !important;
}
</style>