<template>
    <Widget :widget_id="widget_id" :id="'WIDGET'+widget_id">
		<div class="widget-attrs"
            @scroll="onScroll"
        >
            <component v-for="attr in WIDGET.view"
                :widget_id="widget_id" 
                :key="attr.html_id"
                :is="attr.component"
                :attr_id="attr.id"
            />
        </div>
    </Widget>
</template>

<script>
import { useMainStore } from '@/stores/mainStore'

export default {
    props: [
        'widget_id',
    ],
    data: () => ({ 
        WIDGET: {},
    }),
    setup() {
        const store = useMainStore();
        return { store }
    },
    created() {
        // console.log('created')
        const WIDGET = this.WIDGET = this.store.findWidget(this.widget_id).WIDGET
        this.WIDGET.vueObj = this
        
        this.store.widget_checkLoading(this)

        const WORKSPACE = this.store.WORKSPACES[WIDGET.workspace]
        this.PAGE = WORKSPACE.PAGES[WIDGET.page]
    },
    methods:{
        load_data() {
        },
        get_flex(binding) {
            if (!binding) {
                binding='_doc.data'
            }
            if (!this.WIDGET.view) {
                return {}
            }
            let attr = this.store.attr_find_by_keys(this.WIDGET.view, binding)
            if (!attr || !this.WIDGET.attrs_vueObj[attr.id]) {
                return {}
            }
            return this.WIDGET.attrs_vueObj[attr.id].flex
        },
        get_vueObj(binding) {
            if (!binding) {
                binding='_doc.data'
            }
            if (!this.WIDGET.view) {
                return {}
            }
            let attr = this.store.attr_find_by_keys(this.WIDGET.view, binding)
            if (!attr) {
                return {}
            }
            return this.WIDGET.attrs_vueObj[attr.id]
        },
        get_selectedRow(binding='_doc.data') {
            let attr_vueObj = this.get_vueObj(binding)
            if (!attr_vueObj) {
                return null
            }
            let flex = attr_vueObj.flex
            if (!flex) {
                return null
            }
            let attr = attr_vueObj.attr
            let rows = flex.selectedRows
            if (attr.attr_type === 'AttrPivotTool') {
                if (flex.selection.row >= 0 && flex.selection.col >= 0) {
                    let detail = flex.getDetail(flex.selection.row, flex.selection.col)
                    return {iroot:String(flex.selection.row), icol:flex.selection.col, dataItem:detail[0]}
                }
            } else if (flex.selectedItem) {
                // console.log(flex.selectedItem)
                let dataItem = flex.selectedItem
                dataItem['$cutMarker'] = 1
                let iroot = attr_vueObj.iroot?.() || ''
                delete dataItem['$cutMarker']

                return {iroot, dataItem}
            } else if (rows && rows.length) {
                // console.log(rows[0].dataItem)
                let dataItem = rows[0].dataItem,
                iroot = ''
                if (dataItem && this.store.isinstance_dict(dataItem)) {
                    dataItem['$cutMarker'] = 1
                    iroot = attr_vueObj.iroot()
                    delete dataItem['$cutMarker']

                    if (attr_vueObj.parentDataItem_iroot) {
                        iroot = String(attr_vueObj.parentDataItem_iroot) + '.' + attr_vueObj.attr.childItemsPath + '.' + iroot
                    }
                }

                return {iroot, dataItem}
                // return {iroot:'0', dataItem:rows[0].dataItem}
            }
            return null
        },
        get_selectedRanges(binding='_doc.dataSheet') {
            let attr_vueObj = this.get_vueObj(binding)
            if (!attr_vueObj) {
                return null
            }
            let flex = attr_vueObj.flex
            if (!flex) {
                return null
            }
            if (!flex.selectedRanges.length) {
                return null
            }
            return attr_vueObj.get_selectedRanges()
        },
        get_selectedItems(binding='_doc.data') {
            let flex = this.get_flex(binding),
                rows = flex.selectedRows,
                items = []
            if (flex.selectedItem) {
                items.push(flex.selectedItem)
            } else if (rows && rows.length) {
                for (let row of rows)
                    if (row.dataItem && row.isVisible)
                        items.push(row.dataItem)
            }
            return items
        },
        content_Changed_attr_vueObj(binding, action) {
            let attr_vueObj = this.get_vueObj(binding)
            if (attr_vueObj && attr_vueObj.content_Changed) {
                attr_vueObj.content_Changed(action)
            }
        },
        content_Changed(attrs=null, command=null, filters=null) {
            // html_id + '1' -- this will generate ne key for v-for
            let change_html_id = false
            if (attrs==null) {
                attrs = this.WIDGET.view
            }

            for (let attr of attrs) {
                if (attr.use !== false) {
                    if (!change_html_id) {
                        if (!filters || filters.includes(attr.attr_type)) {
                            let vueObj = this.WIDGET.attrs_vueObj[attr.id]
                            if (vueObj) {
                                vueObj.content_Changed(command)
                            }
                        }
                    } else {
                        if (attr.html_id) {
                            attr.html_id += '1'
                        }
                    }
                    if (attr.attrs) {
                        this.content_Changed(attr.attrs, command, filters)
                    }
                }
            }
        },
        onScroll(e) {
            this.PAGE.vueObj.refreshe_svg()
        },
    }
}
</script>

<style scoped>
.widget-attrs {
    height: 100%;
    width: 100%;
    overflow: auto;
}
</style>