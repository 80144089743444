<template>
    <Attr :widget_id="widget_id" :attr_id="attr_id">
        <span 
            :class="attr.cssClass"
            v-html="attr.title || ''"
        />
    </Attr>
</template>

<script>
import { useMainStore } from '@/stores/mainStore'

export default {
    props: {
        attr_id: String,
        widget_id: [String, Number],
        initialAttr: {
            type: Object,
            default: null,
        },
    },
    data: () => ({
        attr: {},
        WIDGET: {},
        // isVisible: false,
    }),
    setup() {
        const store = useMainStore();
        return { store }
    },
    created() {
        // console.log(`created ${this.attr_id}`)
        this.WIDGET = this.store.findWidget(this.widget_id).WIDGET
        // this.content_Changed()
        if (this.initialAttr) {
            this.attr = this.initialAttr
        }

        // if (this.attr.shortkey) {
        // //    this.$shortkey(this.attr.hotkey, 'onClick');
        // }
        // this.store.prepare_actionMenu(this.attr)
    },
    methods: {
        // content_Changed() {
        //     this.store.widget_attr_set(this)
        
        //     // this.text = this.store.attr_get(this.WIDGET, this.attr)
        //     const [doc, binding] = this.store.attr_get_link(this.WIDGET, this.attr)
        //     this.doc = doc, this.binding = binding
        // },
        // attr_set(input) {
        //     if (this.doc[this.binding] !== input.text) {
        //         this.store.attr_set(this.WIDGET, this.attr, input.text)
        //     }
        // },
        // tooltip() {
        //     return this.store.tooltip_attr(this)
        // },
    },
	computed: {
	},
}
</script>

<style scoped>
.attr-text {
    width:-webkit-fill-available !important;
}
</style>