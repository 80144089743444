<template>
    <div class="chips-container"
        :class="{ 'drag-over': isDragging }"
        @dragover.prevent="onDragOver"
        @dragleave="onDragLeave"
        @drop="onDrop"
    >
        <div class="chips-icon" v-if="chips.length === 0">
            <i class="pi pi-search"></i>
        </div>

        <div class="input-container" @click="focusInput">
            <div v-for="(chip, index) in chips" :key="index" :class="['chip', chip.cssClass || '']"
                @mouseover="hoverIndex = index"
                @mouseleave="hoverIndex = null" 
            >
                <span :class="['chip-icon', 'pi', hoverIndex === index ? 'pi-cog' : chip.pi]" />

                <span v-if="!chip.isEditing" class="chip-text" @dblclick="editChip(index)">
                    {{ chip.title }}
                </span>
                <input v-else
                    v-model="chip.title"
                    ref="chipInputs"
                    class="chip-input"
                    @blur="saveChip(index)"
                    @keydown.enter="saveChip(index)"
                />
                <i class="chip-remove pi pi-times-circle" @click="removeChip(index)" />
            </div>

            <input 
                v-if="editable"
                ref="input"
                type="text"
                v-model="newChipText"
                class="chips-input"
                placeholder="Search..."
                @keydown.enter="addChip"
                @keydown.backspace="removeLastChip"
                @blur="editable = false"
            />
        </div>

        <div class="dropdown-button">
            <i class="pi pi-chevron-down"></i>
        </div>
    </div>
</template>

<script>
import { ref, nextTick, defineComponent, watch } from "vue";
import { useMainStore } from '@/stores/mainStore'
import * as wjcCore from '@mescius/wijmo';

export default defineComponent({
    props: {
        modelValue: Array, // v-model {
        //     use, title, 
        //     pi, cssClass,
        //     attr_type: 'AttrChipSearch', 'AttrChipWhere', 'AttrChipOrder', 'AttrChipGroup', 'AttrChipTree', AttrChipGridNode
        //     leftValue, condition, rightValue 
        // }
    },
    data() {
        return {
            editable: false,
            isDragging: false,
        };
    },
    setup(props, { emit }) {
        const chips = ref(props.modelValue.map(chip => ({ ...chip, isEditing: false })));
        const newChipText = ref("");
        const input = ref(null);
        const hoverIndex = ref(null);
        const chipInputs = ref([]);
        const store = useMainStore()

        const addChip = () => {
            if (newChipText.value.trim() !== "") {
                const newChip = {
                    use: true,
                    attr_type: "AttrChipSearch",
                    pi: "pi-search",
                    title: newChipText.value.trim(),
                };
                chips.value.push(newChip);
                emit("update:modelValue", chips.value);
                emit("add", newChip);
                newChipText.value = "";
            }
        };

        const removeChip = (index) => {
            const removedChip = chips.value.splice(index, 1)[0];
            emit("update:modelValue", chips.value);
            emit("remove", removedChip);
            hoverIndex.value = null;
        };

        const removeLastChip = (event) => {
            if (event.key === "Backspace" && newChipText.value === "" && chips.value.length > 0) {
                removeChip(chips.value.length - 1);
                event.preventDefault();
            }
        };

        const editChip = (index) => {
            const chip = chips.value[index]
            if (chip.attr_type === "AttrChipSearch") {
                chip.isEditing = true;
                nextTick(() => {
                    if (chipInputs.value[0]) {
                        chipInputs.value[0].focus();
                        chipInputs.value[0].select();
                    }
                });
            }
        };
        const saveChip = (index) => {
            chips.value[index].isEditing = false;
            emit("update:modelValue", chips.value);
        };

        watch(() => props.modelValue, (newVal) => {
            chips.value = [...newVal];
        });
        watch( () => props.modelValue, (newVal) => {
                chips.value = newVal.map(chip => ({
                    ...chip,
                    isEditing: false
                }));
            },
            { deep: true, immediate: true }
        );

        return { store, emit, chips, hoverIndex, chipInputs, newChipText, addChip, removeChip, removeLastChip, input, editChip, saveChip };
    },
    methods: {
        onDragOver(event) {
            if (this.store.dataTransfer?.type === 'grid-column') {
                event.preventDefault();
                this.isDragging = true;
            }
        },
        onDragLeave() {
            this.isDragging = false;
        },
        onDrop(event) {
            event.preventDefault();
            this.isDragging = false;

            // const files = event.dataTransfer.files;
            if (this.store.dataTransfer?.type === 'grid-column') {
                const attr_col = this.store.dataTransfer.attr_col
                const chip = this.setChipGroup(attr_col)

                this.emit("update:modelValue", this.chips);
                this.emit("add", chip);
            }
        },

        setChipGroup(attr_col) {
            let chip = this.chips.find(chip => chip.attr_type === "AttrChipGroup");
            if (!chip) {
                chip = {
                    use: true,
                    attr_type: "AttrChipGroup",
                    pi: "pi-play",
                    groupDescriptions: [],
                }
                this.chips.push(chip)
            }

            chip.groupDescriptions.push({binding:attr_col.binding, title:attr_col.title || attr_col.binding});
            chip.title = chip.groupDescriptions.map(group => group.title).join(", ");

            return chip
        },
        getChipGroup(forCompare=false) { // >> Grid
            let groupDescriptions = []
            let chip = this.chips.find(chip => chip.attr_type === "AttrChipGroup");
            if (chip) {
                if (!forCompare) {
                    groupDescriptions = chip.groupDescriptions.map(group => group.binding)
                } else {
                    groupDescriptions = chip.groupDescriptions.map(group => group.binding)
                }
            }
            return groupDescriptions
        },
        compareChipGroup(vueGrid) {
            let groupDescriptions = this.getChipGroup(true)
            const current_groupDescriptions = vueGrid.flex.itemsSource.groupDescriptions.map(group => group.propertyName);

            return JSON.stringify(groupDescriptions) === JSON.stringify(current_groupDescriptions)
        },

        setChipOrder(vueGrid) {
            let chip = this.chips.find(chip => chip.attr_type === "AttrChipOrder");
            if (!chip) {
                chip = {
                    use: true,
                    attr_type: "AttrChipOrder",
                    pi: "pi-sort",
                    sortDescriptions: [],
                }
                this.chips.push(chip)
            }

            chip.sortDescriptions = vueGrid.flex.collectionView.sortDescriptions.map(sortDesc => ({
                binding: sortDesc.property,
                title: vueGrid.attr_by_binding[sortDesc.property]?.title || sortDesc.property,
                direction: sortDesc.ascending ? 'ASC' : 'DESC'
            }));
            chip.title = chip.sortDescriptions.map(row => row.title).join(", ");

            if (chip.sortDescriptions.length === 0) {
                const index = this.chips.indexOf(chip);
                this.chips.splice(index, 1);
            }

            this.emit("update:modelValue", this.chips);

            return chip
        },
        getChipOrder(forCompare=false) { // >> Grid
            let sortDescriptions = []
            let chip = this.chips.find(chip => chip.attr_type === "AttrChipOrder");
            if (chip) {
                if (!forCompare) {
                    sortDescriptions = chip.sortDescriptions.map(row => new wjcCore.SortDescription(row.binding, row.direction === 'ASC'))
                } else {
                    sortDescriptions = chip.sortDescriptions.map(group => (group.binding, group.direction))
                }
            }
            return sortDescriptions
        },
        compareChipOrder(vueGrid) {
            let sortDescriptions = this.getChipOrder(true)
            const current_sortDescriptions = vueGrid.flex.collectionView.sortDescriptions.map(sortDesc => ((
                sortDesc.property,
                sortDesc.ascending ? 'ASC' : 'DESC'
            )));

            return JSON.stringify(sortDescriptions) === JSON.stringify(current_sortDescriptions)
        },

        setChipWhere(title, binding, condition, value) {
            let chip = this.chips.find(chip => chip.attr_type === "AttrChipWhere" && chip.leftValue.value === binding);
            if (!chip) {
                chip = {
                    use: true,
                    pi: 'pi-filter',
                    cssClass: binding,
                    attr_type: 'AttrChipWhere',
                    leftValue: {value:binding},
                    condition: condition, 
                }
                this.chips.push(chip)
            }

            chip.rightValue = {value:value}
            chip.title = title || `${binding}=${value}`

            this.emit("update:modelValue", this.chips);

            return chip
        },

        setChipGridFilter(vueGrid, filter) {
            let chip = this.chips.find(chip => chip.attr_type === "AttrChipGridFilter");
            if (!chip) {
                chip = {
                    use: true,
                    pi: 'pi-filter',
                    attr_type: 'AttrChipGridFilter',
                }
                this.chips.push(chip)
            }

            chip.filterDefinition = filter.filterDefinition

            let filterDefinition = JSON.parse(filter.filterDefinition)
            chip.title = filterDefinition.filters.map(row => 
                vueGrid.attr_by_binding[row.binding]?.title || row.binding,
            ).join(", ");

            if (!chip.title) {
                const index = this.chips.indexOf(chip);
                this.chips.splice(index, 1);
            }
           
            this.emit("update:modelValue", this.chips);

            return chip
        },
        getChipGridFilter() {
            let chip = this.chips.find(chip => chip.attr_type === "AttrChipGridFilter");
            return chip?.filterDefinition || '{"defaultFilterType":3,"filters":[]}'
        },
        compareGridFilter(vueGrid, filter) {
            let filterDefinition = this.getChipGridFilter()
            let current_filterDefinition = filter?.filterDefinition
            
            return filterDefinition === current_filterDefinition
        },
        focusInput(event) {
            if (event.target === this.$refs.input || event.target.closest('.chip')) return;
            this.editable = true;
            nextTick(() => {
                this.$refs.input?.focus();
            });
        }
    },
});
</script>

<style scoped>
.chips-container {
    display: flex;
    border: 1px solid #ccc;
    border-radius: 3px;
}
.chips-container.drag-over {
    border-color: #2196f3;
    background-color: rgba(33, 150, 243, 0.1);
}
.input-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 4px;
    padding: 4px;
    width: -webkit-fill-available;
    cursor: text;
}

.chips-icon {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #ccc;
    margin-left: 8px;
}

.chip {
    display: flex;
    align-items: center;
    background: rgb(222 226 230 / 75%);
    color: var(--text-color);
    border-radius: 3px;
    font-size: 14px;
    white-space: nowrap;
    /*border: 1px solid #ccc;*/
}

.chip-icon {
    background: rgba(119, 136, 153, 0.8);
    padding: 4px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    color: white;
    cursor: pointer;
}

.chip-text {
    margin-left: 4px;
    /*font-stretch: ultra-condensed;
    font-size: 1.3rem;
    font-weight: bold;*/
}

.chip-remove {
    background: transparent;
    border: none;
    cursor: pointer;
    margin: 4px;
    opacity: 0.6;
}

.chip-remove:hover {
    color: #d32f2f;
}

.chip-input {
    border: none;
    outline: none;
    font-size: 14px;
    padding: 2px;
    min-width: 80px;
    background: transparent;
}
.chips-input {
    border: none;
    outline: none;
    font-size: 14px;
    min-width: 50px;
    flex-grow: 0;
}

.dropdown-button {
    display: flex;
    align-items: center;
    font-size: 16px;
    padding: 8px;
    border-left: 1px solid #ccc;
    cursor: pointer;
}

.dropdown-button i {
    color: #ccc;
}

.chips-container .collection {
    background: rgba(142, 160, 255, 0.75);
    color: white;
}
.chips-container .segment {
    background: rgba(142, 160, 255, 0.75);
    color: white;
}
.chips-container .widget {
    background: rgba(204, 153, 51, 0.75);
    color: white;
}
.chips-container .status {
    background: rgba(244, 67, 54, 0.75);
    color: white;
}

.chip:hover {
    background: rgb(240 240 240 / 75%);
    color: var(--text-color);
}

</style>

<style>
.collection {
    background: rgba(142, 160, 255, 0.75);
    color: white;
}
.segment {
    background: rgba(142, 160, 255, 0.75);
    color: white;
}
.widget {
    background: rgba(204, 153, 51, 0.75);
    color: white;
}
.status {
    background: rgba(244, 67, 54, 0.75);
    color: white;
}
</style>