<template>
    <Attr :widget_id="widget_id" :attr_id="attr_id">
        <!-- <div class="attr-containerResize" :id="'containerResize'+attr.html_id"> -->
            <div class="label-sheet" v-if="attr.title && !attr.hideTitle" :for="attr.html_id">{{ attr.title }}</div>
            <div class="attr-PIVOT-TOOL" ref="attr_container">
                <div v-if="attr.showPivotPanel !== undefined ? attr.showPivotPanel : false">
                    <wj-pivot-panel
                        :items-source="engine" 
                    />
                </div>

                <div v-if="attr.showPivotChart !== undefined ? attr.showPivotChart : false">
                    <wj-pivot-chart 
                        :id="`${attr.html_id}_chart`"
                        :items-source="engine" 
                        :show-title="false" 
                        show-legend="Auto"
                        style="width: -webkit-fill-available;"
                        :initialized="initializePivotChart"
                    />
                </div>
                
                <div v-if="(attr.showPivotGrid !== undefined ? attr.showPivotGrid : false)">
                    <wj-pivot-grid 
                        :id="attr.html_id"
                        :initialized="initialized" 
                        :formatItem="formatItem" 
                        :refreshed="refreshed" 
                        :showDetailOnDoubleClick="true"
                        :copyHeaders="'None'"
                        v-visible="handleVisibility"

                        :items-source="engine" 
                        show-selected-headers="All"
                        :outline-mode="true"
                        @keydown="keydown"
                        :keyActionEnter="'None'" 
                        :showMarquee="true"
                        :showSelectedHeaders="'All'"

                        :frozenRows="attr.frozenRows || 0"
                        :frozenColumns="attr.frozenColumns || 0"
                    />
                </div>
            </div>
        <!-- </div> -->
    </Attr>
</template>

<script>
import { useMainStore } from '@/stores/mainStore'
import '@mescius/wijmo.vue2.chart.hierarchical';
import { isArray } from '@mescius/wijmo';
import { Palettes } from '@mescius/wijmo.chart';
import * as wjcCore from '@mescius/wijmo';
import * as wjcOlap from '@mescius/wijmo.olap';
let tooltip = new wjcCore.Tooltip({
    showDelay: 1500,
});

export default {
    props: [
        'widget_id',
        'attr_id',
    ],
    data: () => ({ 
        WIDGET: {},
        engine: null,
        attr_by_binding: {},
        wait_itemsSource: {},
        inputs: {},
        attrsGroup: [],
        cache_detail: {},

        changesStack: {},

        fields: [],
    }),
    setup() {
        const store = useMainStore();
        return { store }
    },
    created() {
        // console.log(`created ${this.attr_id}`)
        this.WIDGET = this.store.findWidget(this.widget_id).WIDGET
        this.content_Changed()

        this.palette = this.getRandomPalette()
    },
    mounted() {
        if (this.attr.showPivotGrid !== undefined ? this.attr.showPivotGrid : false) {
            this.WIDGET.attrsResize.push(this.attrResize)
        }
        if (this.attr.showPivotChart !== undefined ? this.attr.showPivotChart : false) {
            this.WIDGET.attrsResize.push(this.attrResize_chart)
        }
    },
    methods: {
        formatItem(flex, e) {
            let col = flex.columns[e.col],
                row = flex.rows[e.row]
            if (e.panel === flex.cells) {
                    // item = row.dataItem,
                    // col_binding = this.field_by_cell(col.binding).binding
                
            //     e.cell.innerHTML = ''
            //     let input = document.createElement('input')
            //     input.classList = ["wj-grid-editor wj-widget-control"]
            //     input.type = 'tel'
            //     // autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="-1" required="" aria-required="true" 
            //     input.style = "text-align: right;" 
            //     input.value = flex.getCellData(e.row, e.col, true)
            //     input.oldValue = input.value
            //     input.data_cell = {
            //         row: e.row,
            //         col: e.col,
            //         binding: col.binding,
            //     }
            //     e.cell.appendChild(input)
            //     input.addEventListener('change', this.input_Change)
            //     input.addEventListener('paste', this.input_Paste)
            //     input.addEventListener('keyup', this.input_Keyup)
            //     this.inputs[`${e.row}:${e.col}`] = input

            //     // MD.tooltip, cellsStyle
            //     if (this.attr.have_MD) {
            //         let detail = this.get_detail(e.row, e.col)
            //         if (detail.length==1) {
            //             let item = detail[0]
            //             if (item && item.MD) {
            //                 if (item.MD.tooltip && item.MD.tooltip[col_binding]) {
            //                     tooltip.setTooltip(e.cell, item.MD.tooltip[col_binding]);
            //                 }
            //                 if (item.MD.rowStyle) {
            //                     e.cell.style.cssText += item.MD.rowStyle
            //                 }
            //                 if (item.MD.cellsStyle && item.MD.cellsStyle[col_binding]) {
            //                     // console.log(`${irow}[${col_binding}]=${item[col_binding]}, ${item.MD.cellsStyle[col_binding]}`)
            //                     e.cell.style.cssText += item.MD.cellsStyle[col_binding]
            //                 }
            //                 if (item.MD.cssClass && item.MD.cssClass[col_binding]) {
            //                     wjcCore.addClass(e.cell, item.MD.cssClass[col_binding])
            //                 }
            //             }
            //         }
            //     }

            //     // changesStack
            //     if (`${e.row}:${e.col}` in this.changesStack) {
            //         wjcCore.addClass(e.cell, 'wj-has-stack')
            //         tooltip.setTooltip(e.cell, `<b>Old value:</b><br/>${this.changesStack[`${e.row}:${e.col}`]}`);
            //     }
            } else if (e.panel === flex.columnHeaders) {
                if (e.cell.innerText.startsWith("~ ... (")) {
                    wjcCore.addClass(e.cell, 'dimensions-over')
                }
                // let col = flex.columns[e.col],
                //     col_binding = this.field_by_cell(col.binding).binding,
                //     attr = this.attr_by_binding[col_binding]

            //     if (attr) {
            //         if (attr.tooltip) {
            //             tooltip.setTooltip(e.cell, attr.tooltip)
            //         } else {
            //             tooltip.setTooltip(e.cell, attr.title)
            //         }
            //         if (attr.cssClass) {
            //             wjcCore.addClass(e.cell, attr.cssClass)
            //         }
            //     }
            } else if (e.panel === flex.rowHeaders) {
                if (e.cell.innerText.startsWith("~ ... (")) {
                    wjcCore.addClass(e.cell, 'dimensions-over')
                }
            //     if (e.cell.textContent.includes('  |  ')) {
            //         // console.log(e.cell.innerHTML)
            //         const cells = e.cell.textContent.split(/\s*\|\s*/)
            //         const htmlCells = cells.map(cell => `<td style="width:150px; text-align:left;">${cell.trim()}</td>`).join('')
            //         e.cell.innerHTML = e.cell.innerHTML.replace(e.cell.textContent, `
            //         <table>
            //             <tbody>
            //                 <tr>
            //                     ${htmlCells}
            //                 </tr>
            //             </tbody>
            //         </table>`)

            //         // ${100 / cells.length}%
            //     }
            }
        },
        content_Changed() {
            this.store.widget_attr_set(this)
            
            if (this.flex) {
                this.attrResize()
                
                this.data = this.store.attr_get(this.WIDGET, this.attr)
                this.cache_detail = {}
                
                // attrsGroup
                let attrGroup = {}
                this.attrsGroup = []
                for (let attr of this.attr.attrs) {
                    let visible = (!('visible' in attr) || attr.visible)
                    if (visible && attr.join && attrGroup) {
                        if (!attrGroup.isGroup) {
                            let attrGroup2 = {...attrGroup}
                            attrGroup2.binding += '_Group'
                            attrGroup2.isGroup = true
                            attrGroup2.attrs = [attrGroup]
                            this.attrsGroup.push(attrGroup2)
                            attrGroup = attrGroup2
                        }
                        attrGroup.attrs.push(attr)
                        attrGroup.title = `${attrGroup.title} / ${attr.title}`
                    } else {
                        attrGroup = attr
                    }
                }
                for (let attrGroup of this.attrsGroup) {
                    // data++
                    for (let row of this.data) {
                        row[attrGroup.binding] = ''
                        for (let attr of attrGroup.attrs) {
                            row[attrGroup.binding] += row[attr.binding]
                        }
                    }

                    // attrs++
                    var index = this.attr.attrs.indexOf(attrGroup.attrs[0])
                    this.attr.attrs.splice(index, 0, attrGroup)
                    for (let attr of attrGroup.attrs) {
                        attr.inGroup = true
                    }
                }

                // have_MD
                if (this.data) {
                    for (let row of this.data) {
                        if (row.MD) {
                            this.attr.have_MD = true
                            break
                        }
                    }
                }
                    
                // if (!this.fields.length) {
                    this.load_PANEL()
                // }

                this.set_engine()
                this.set_commandPanel(true)
            }
        },
        set_engine() {
            const store = this.store,
            WIDGET = this.WIDGET
            // if (!this.fields.length) {
            //     return
            // }
            for (let binding in this.wait_itemsSource) {
                if (this.wait_itemsSource[binding]) return
            }

            // data++
            for (let attrGroup of this.attrsGroup) {
                for (let row of this.data) {
                    row[attrGroup.binding] = ''
                    for (let attr of attrGroup.attrs) {
                        if (row[attrGroup.binding]) {
                            row[attrGroup.binding] += '  |  '
                        }
                        if (`${attr.binding}.title` in row) {
                            row[attrGroup.binding] += row[`${attr.binding}.title`]
                        } else {
                            row[attrGroup.binding] += row[attr.binding]
                        }
                    }
                }
            }

            // save view
            let viewDefinition = null,
                sd = null
            if (this.engine && this.engine.fields.length === this.fields.length) {
                viewDefinition = this.engine.viewDefinition
                sd = this.engine.pivotView.sortDescriptions
            }

            // let engine_data = new wjcCore.CollectionView(this.data||[])
            this.engine = new wjcOlap.PivotEngine({
                itemsSource: this.data||[],
                
                autoGenerateFields: false,
                fields: this.fields,
                rowFields: this.rowFields,
                columnFields: this.columnFields,
                valueFields: this.valueFields,
                
                showRowTotals: 'Subtotals', // wjcOlap.ShowTotals.Subtotals
                showColumnTotals: 'Subtotals',
                totalsBeforeData: true,

                updatingView: () => {
                    if (this.start === 0) {
                        this.start = Date.now()
                    }
                },
                updatedView: (s) => {
                    store.TERMINAL(WIDGET, null, {text:`PivotEngine summarized ${s.itemsSource.length} items in ${Date.now() - this.start}ms`, status:'info'})
                    this.start = 0
                }
            })

            // // load view
            // if (viewDefinition) {
            //     this.engine.viewDefinition = viewDefinition
            //     for (let s of sd) {
            //         this._addMeasureSort(s.property, s.ascending ? 'Ascending' : 'Descending')
            //     }
            // }

            // if (this.flexPanel) {
            //     this.flexPanel.engine = this.engine
            // }

            let attr_dataPivotPanel = this.store.attr_find_by_keys(this.WIDGET.view, 'dataPivotPanel')
            if (attr_dataPivotPanel) {
                let dataPivotPanel_vueObj = this.WIDGET.attrs_vueObj[attr_dataPivotPanel.id]
                dataPivotPanel_vueObj.engine = this.engine
            }

            let attr_dataPivotChart = this.store.attr_find_by_keys(this.WIDGET.view, 'dataPivotChart')
            if (attr_dataPivotChart) {
                let dataPivotChart_vueObj = this.WIDGET.attrs_vueObj[attr_dataPivotChart.id]
                dataPivotChart_vueObj.engine = this.engine
            }
        },
        load_PANEL() {
            let attr_quantity = '',
                attr_price = '',
                attr_sum = ''

            for (let attr of this.attr.attrs) {
                if (attr.calculationModel === 'CALCULATION-MODEL_quantity') {
                    attr_quantity = attr
                } else if (attr.calculationModel === 'CALCULATION-MODEL_price') {
                    attr_price = attr
                } else if (attr.calculationModel === 'CALCULATION-MODEL_sum') {
                    attr_sum = attr
                }
            }

            this.attr.tree_bindingName = []
            this.fields = []
            this.rowFields = []
            this.columnFields = []
            this.valueFields = []
            for (let attr of this.attr.attrs) {
                let col = {}
                col.binding = attr.binding
                col.header = attr.title
                // col.align = attr.align
                col.width = attr.width
                let visible = (!('visible' in attr) || attr.visible)

                if (attr.attr_type === 'AttrLink') {
                    if (!attr.isGroup) {
                        col.binding = `${attr.binding}.title`
                        this.attr.tree_bindingName.push(attr.binding)
                        col.width = attr.width || 200
                    }
                } else if (attr.attr_type === 'AttrNumber') {
                    if (attr.format) {
                        col.format = attr.format
                    } else {
                        col.format = 'n0'
                    }
                } 
                if (attr.calculationModel === 'CALCULATION-MODEL_price' && attr_quantity && attr_sum) {
                    col.getAggregateValue = row => row[attr_quantity.title] ? row[attr_sum.title] / row[attr_quantity.title] : 0
                }
                
                if (attr.inGroup) continue

                this.attr_by_binding[col.binding] = attr

                this.fields.push(col)

                if (visible) {
                    if (attr.parent_binding === 'F_columns') { // attr.isColumnField
                        this.columnFields.push(col.header)
                    } else if (attr.parent_binding === 'F_values') { // attr.attr_type === 'AttrNumber'
                        this.valueFields.push(col.header)
                        col.aggregate = attr.aggregate||'Sum'
                        
                        if (attr.addShowAs === 'PctCol') {
                            let col2 = {
                                binding: col.binding,
                                header: `% ${col.header}`,
                                format: 'p2', 
                                showAs: 'PctCol'
                            }
                            this.fields.push(col2)
                            this.valueFields.push(col2.header)
                        }
                    } else { // F_rows
                        this.rowFields.push(col.header)
                    }
                }
            }

            // itemsSource
            for (let bindingX of this.attr.tree_bindingName||[]) {
                if (!bindingX.includes('date') && !bindingX.endsWith('.title')) {
                    for (let attr of this.attr.attrs) {
                        if (attr['binding'] === bindingX) {
                            if (attr.relation_segment) { //  && attr.relation_segment==this.store.itemsSource.change_type
                                // console.log(`watch ${this.store.itemsSource.change_type} ${newVal}`)
                                let itemsSource = this.store.get_itemsSource(this, this.data, attr, 'fill.title')
                                this.wait_itemsSource[attr['binding']] = !itemsSource.dataMap
                                break
                            }
                        }
                    }
                }
            }
        },
        input_Change(e) {
            let cell = e.target.data_cell
            this.cell_Change(cell, e.target.value.replace('%',''))
        },
        field_by_cell(cell_binding) {
            let field = null, attr = null,
                bindingJS = this.store.str_toJSON(this.WIDGET, cell_binding, 'cell')

            for (let field1 of this.engine.valueFields) {
                if (field1.header in bindingJS) {
                    field = field1
                    break
                }
            }
            if (!field) return {binding:''}
            // for (let attr1 in this.attr.attrs) {
            //     if (attr1.binding === field.binding) {
            //         attr = attr1
            //     }
            // }

            return field
        },
        get_detail(row, col) {
            if (!this.cache_detail || !this.cache_detail[row]) {
                this.cache_detail[row] = {
                    detail: this.flex.getDetail(row, col),
                    row: row,
                    col: col,
                }
            }
            return this.cache_detail[row].detail
        },
        cell_Change(cell, value) {
            console.log(cell);
            let detail = this.flex.getDetail(cell.row, cell.col),
                field = this.field_by_cell(cell.binding),
                binding = field.binding
            
            // changesStack
            let input = this.inputs[`${cell.row}:${cell.col}`]
            this.changesStack[`${cell.row}:${cell.col}`] = input.oldValue

            let distribute = +Math.max(wjcCore.changeType(value, wjcCore.DataType.Number), 0)
            if (field.showAs === 7) { // wjcCore.ShowAs.PctCol
                if (distribute > 100) {
                    distribute = 100
                }
                let detailColumn = this.flex.getDetail(0, cell.col),
                    detailColumn_rest = [],
                    baseColumn = 0
                for (let row of detailColumn) {
                    if (row[binding]) {
                        baseColumn += row[binding]
                    }
                    if (detail.indexOf(row) === -1) {
                        detailColumn_rest.push(row)
                    }
                }

                if (!detailColumn_rest.length) {
                    this.engine.refresh()
                    return
                }

                distribute = +(baseColumn * distribute / 100).toFixed(0)
                this.distributeOnRows(detailColumn_rest, baseColumn - distribute, binding)
            }
            this.distributeOnRows(detail, distribute, binding)
            this.engine.refresh()
        },
        distributeOnRows(detail, distribute, binding) {
            let base = 0,
                maxRow = null,
                binding_quantity = '',
                binding_price = '',
                binding_sum = ''

            for (let attr of this.attr.attrs) {
                if (attr.calculationModel === 'CALCULATION-MODEL_quantity') {
                    binding_quantity = attr.binding
                } else if (attr.calculationModel === 'CALCULATION-MODEL_price') {
                    binding_price = attr.binding
                } else if (attr.calculationModel === 'CALCULATION-MODEL_sum') {
                    binding_sum = attr.binding
                }
            }

            if (binding === binding_price) {
                for (let row of detail) {
                    row[binding] = distribute
                    this.quantity_CALC(row, binding, binding_quantity, binding_price, binding_sum)
                }
                return
            }

            for (let row of detail) {
                if (row[binding]) {
                    base += row[binding]
                    if (maxRow === null || (row[binding] > maxRow[binding])) {
                        maxRow = row
                    }
                }
            }
            if (!maxRow && detail.length) {
                maxRow = detail[0]
            }

            let rest = distribute
            if (base) {
                for (let row of detail) {
                    if (row[binding]) {
                        // price_CALC
                        if (binding_quantity && !binding_price && binding_sum) {
                            if (!row.price_CALC && row[binding_quantity]) {
                                row.price_CALC = +(row[binding_sum] / row[binding_quantity]).toFixed(5)
                            }
                        }

                        let canTake = +Math.min(rest, (row[binding] / base * distribute).toFixed(0))
                        rest -= canTake
                        row[binding] = canTake
                        this.quantity_CALC(row, binding, binding_quantity, binding_price, binding_sum)
                    }
                }
            }
            if (rest) {
                if (maxRow[binding]) {
                    maxRow[binding] += rest
                } else {
                    maxRow[binding] = rest
                }
                this.quantity_CALC(maxRow, binding, binding_quantity, binding_price, binding_sum)
            }
        },
        quantity_CALC(row, binding, binding_quantity, binding_price, binding_sum) {
            if (binding === binding_quantity) {
                if (binding_price && binding_sum) {
                    row[binding_sum] = +(row[binding_quantity] * row[binding_price]).toFixed(0)
                } else if (binding_sum) {
                    if (row.price_CALC) {
                        row[binding_sum] = +(row[binding_quantity] * row.price_CALC).toFixed(0)
                    }
                }
            } else if (binding === binding_price) {
                if (binding_quantity && binding_sum) {
                    row[binding_sum] = +(row[binding_quantity] * row[binding_price]).toFixed(0)
                }
            } else if (binding === binding_sum) {
                if (binding_quantity && binding_price) {
                    if  (row[binding_price]) {
                        row[binding_quantity] = +(row[binding_sum] / row[binding_price]).toFixed(0)
                    }
                } else if (binding_quantity) {
                    if (row.price_CALC) {
                        row[binding_quantity] = +(row[binding_sum] / row.price_CALC).toFixed(0)
                    }
                }
            }

            // undoStack
            this.WIDGET.record._doc.undoStack.push(row)
            if (this.get_viewParam('isReadOnly')) {
                this.set_viewParam('isReadOnly', false)
                this.set_commandPanel()
            }
            // this.WIDGET.title += ' (modified)'
            // this.store.Save_PIVOT_TOOL(this, [row])
        },
        set_commandPanel(reset_flex_isReadOnly) {
            if (reset_flex_isReadOnly) {
                tooltip.dispose()
                this.changesStack = {}
                if (!this.WIDGET.record._doc) this.WIDGET.record._doc = {}
                this.WIDGET.record._doc.undoStack = []
                this.set_viewParam('isReadOnly', true)
            }

        },
        input_Keyup(event) {
            if (event.key === 'Enter') {
                // this.flex.select(1, 1)
                // this.flex.select(e.row, e.col)
                // this.engine.refresh()
            } else if (event.key === 'Escape') {
                // event.target.blur()
                this.engine.refresh()
                // this.flex.focus(true)
            }
        },
        initialized(flex) {
            this.flex = flex
            this.content_Changed()
        },
        initializePivotChart(pivotChart) {
            this.flexChart = pivotChart
            if (this.WIDGET.record.doc.params.chartType) {
                this.flexChart.chartType = wjcOlap.PivotChartType[this.WIDGET.record.doc.params.chartType];
            }
        },
        _addMeasureSort(fieldName, sortDirection) {
            if (sortDirection !== 'None') {
                let sd = this.engine.pivotView.sortDescriptions,
                    cols = this.flex.columns;
                for (let c = 0; c < cols.length; c++) {
                    let binding = cols[c].binding;
                    if (binding?.indexOf(fieldName) === 0) {
                        sd.push(new wjcCore.SortDescription(binding, sortDirection === 'Ascending'));
                    }
                }
            }
        },
        addFilterOnSelectedCells() {
            let obj_viewDefinition = JSON.parse(this.engine.viewDefinition)

            for (let CellRange of this.flex.selectedRanges) {
                for (let irow = Math.min(CellRange.row,CellRange.row2); irow <= Math.max(CellRange.row,CellRange.row2); irow++) {
                    let rowKey = this.flex.rows[irow].dataItem.$rowKey,
                        ivalue = rowKey.values.length - 1,
                        value = rowKey.values[ivalue],
                        header = rowKey.fieldNames[ivalue],
                        field = obj_viewDefinition.fields.find(item => item.header === header)

                    if (!field.filter) {
                        field.filter = {}
                    }
                    if (!field.filter.showValues) {
                        field.filter.showValues = {}
                    }

                    field.filter.showValues[value] = true
                }
            }

            this.engine.viewDefinition = JSON.stringify(obj_viewDefinition)
        },
        clearFilter() {
            let obj_viewDefinition = JSON.parse(this.engine.viewDefinition)

            for (let field of obj_viewDefinition.fields) {
                if (field.filter) {
                    delete field.filter
                }
            }

            this.engine.viewDefinition = JSON.stringify(obj_viewDefinition)
        },
        getRandomPalette() {
            let palettes = Object.keys(Palettes).filter(prop => isArray(Palettes[prop]));
            let rand = Math.floor(Math.random() * palettes.length);
            //
            return Palettes[palettes[rand]];
        },
        attrResize() {
            // if (!this.isVisible) return TODO isVisible not seted
            // console.log('attrResize')
            const elementForm = document.getElementById(`WIDGET${this.widget_id}`);
            // const elementForm = document.getElementById(`containerResize${this.attr.html_id}`)
            const elementFlex = document.getElementById(this.attr.html_id); // this.$refs.attr_container
            // const elementFlex = this.$refs.attr_container // document.getElementById(this.attr.html_id);
            if (!elementForm || !elementFlex) {
                return
            }
            const elementWidget_Rect = elementForm.getBoundingClientRect()
            const elementFlex_Rect = elementFlex.getBoundingClientRect()
            if (elementFlex_Rect.height) {
                let newHeight = Math.floor(elementWidget_Rect.bottom - elementFlex_Rect.top) - 2
                if (this.attr.size) {
                    newHeight = +(newHeight * this.attr.size / 100).toFixed(0)
                }
                if (newHeight < (this.attr.minHeight||160)) {
                    newHeight = (this.attr.minHeight||160)
                }
                if (this.attr.maxHeight && newHeight > this.attr.maxHeight) {
                    newHeight = this.attr.maxHeight
                } else if (newHeight > 1500) {
                    newHeight = 1500
                }
                if (this.attr.heightAdd) {
                    newHeight += this.attr.heightAdd
                }
                if (elementFlex_Rect.height !== newHeight) {
                    console.log('attrResize' +(elementFlex_Rect.height - newHeight))
                    elementFlex.style.height = `${newHeight}px`
                }
            }
        },
        attrResize_chart() {
            // console.log('attrResize')
            const elementForm = document.getElementById(`WIDGET${this.widget_id}`);
            // const elementForm = document.getElementById(`containerResize${this.attr.html_id}`)
            const elementFlex = document.getElementById(`${this.attr.html_id}_chart`); // this.$refs.attr_container
            // const elementFlex = this.$refs.attr_container // document.getElementById(this.attr.html_id);
            if (!elementForm || !elementFlex) {
                return
            }
            const elementWidget_Rect = elementForm.getBoundingClientRect()
            const elementFlex_Rect = elementFlex.getBoundingClientRect()
            if (elementFlex_Rect.height) {
                let newHeight = elementWidget_Rect.bottom - elementFlex_Rect.top
                if (this.attr.size) {
                    newHeight = +(newHeight * this.attr.size / 100).toFixed(0)
                }
                if (newHeight < (this.attr.minHeight||160)) {
                    newHeight = (this.attr.minHeight||160)
                }
                if (this.attr.maxHeight && newHeight > this.attr.maxHeight) {
                    newHeight = this.attr.maxHeight
                } else if (newHeight > 1500) {
                    newHeight = 1500
                }
                if (this.attr.heightAdd) {
                    newHeight += this.attr.heightAdd
                }
                if (elementFlex_Rect.height !== newHeight) {
                    console.log('attrResize' +(elementFlex_Rect.height - newHeight))
                    elementFlex.style.height = `${newHeight}px`
                }
            }
        },
        keydown(e) {
            // console.log(e)
            if (e.target && e.target.children && e.target.children.length && e.target.children[0].data_cell) {
                if (['Delete','Backspace'].includes(e.key)) {
                    for (let CellRange of this.flex.selectedRanges) {
                        for (let row = Math.min(CellRange.row,CellRange.row2); row <= Math.max(CellRange.row,CellRange.row2); row++) {
                            for (let col = Math.min(CellRange.col,CellRange.col2); col <= Math.max(CellRange.col,CellRange.col2); col++) {
                                let input = this.inputs[`${row}:${col}`]
                                if (input)
                                    this.cell_Change(input.data_cell, 0)
                            }
                        }
                    }
                } else if (!['Shift','Meta','Alt','Control','ArrowRight','ArrowLeft','ArrowUp','ArrowDown'].includes(e.key)) {
                    let cell = e.target.children[0].data_cell
                    let input = this.inputs[`${cell.row}:${cell.col}`]
                    input.select()
                }
            }
        },
        input_Paste(e) {
            let cell = null
            if (e.target && e.target.children && e.target.children.length && e.target.children[0].data_cell) {
                cell = e.target.children[0].data_cell
            } else if (e.target && e.target.data_cell) {
                cell = e.target.data_cell
            }

            if (cell) {
                e.preventDefault();

                const clipboardData = e.clipboardData || window.clipboardData
                const pastedText = clipboardData.getData('text')

                // Split the pasted text into rows and columns
                const rows = pastedText.split('\n').map(row => row.trim())
                const pastedTable = rows.map(row => row.split('\t'))

                for (let iRow in pastedTable) {
                    for (let iCol in pastedTable[iRow]) {
                        let value = pastedTable[iRow][iCol]
                        if (value !== '') {
                            let input = this.inputs[`${cell.row+(+iRow)}:${cell.col+(+iCol)}`]
                            if (input)
                                this.cell_Change(input.data_cell, value)
                        }
                    }
                }
            }
        },
        refreshed() {
            this.cache_detail = {}
        },
        handleVisibility(isVisible) {
            this.isVisible = isVisible
            if (isVisible) {
                if (this.attr.getSelectionWorkspace_date){
                    this.WIDGET.getSelectionWorkspace_date = this.getSelectionWorkspace_date
                }
            }
        },

        get_viewParam(param, defaultValue) {
            return this.store.get_viewParam(this.WIDGET, this.attr.name, param, defaultValue, this.attr)
        },
        set_viewParam(param, value) {
            this.store.set_viewParam(this.WIDGET, this.attr.name, param, value, this.attr)
        },
    },
    watch: {
        'store.itemsSource_change_index'(newVal, oldVal) {
            let isChanged = false
            for (let bindingX of this.attr.tree_bindingName||[]) {
                if (!bindingX.includes('date') && !bindingX.endsWith('.title')) {
                    for (let attr of this.attr.attrs) {
                        if (attr['binding'] === bindingX) {
                            if (attr.relation_segment && attr.relation_segment==this.store.itemsSource.change_type) {
                                // console.log(`watch ${this.store.itemsSource.change_type} ${newVal}`)
                                let itemsSource = this.store.get_itemsSource(this, this.data, attr, 'fill.title')
                                this.wait_itemsSource[attr['binding']] = !itemsSource.dataMap
                                isChanged = true
                                break
                            }
                        }
                    }
                }
            }
            if (isChanged) {
                this.set_engine() // TODO
            }
        },
    },
}
</script>

<style>
.dimensions-over {
    font-weight: bold !important;
    background: #d9edf7 !important;
}
</style>