<template>
    <Attr :widget_id="widget_id" :attr_id="attr_id">
        <div class="grid-container">
            <div class="button grid-item grid-item-blue" @click="onClick({'addon':'ADDON_manufacturing'})"
                v-wjTooltip="'Material requirements planning for production'"
            >
                <h2>MANUFACTURING PLANNER</h2>
                <!-- <p>Material requirements planning for production</p> -->
                <img class="img-func" src="images/icon-MANUFACTURING-PLANNER.png" data-mimetype="image/png">
            </div>
            <div class="button grid-item grid-item-center grid-item-green" @click="onClick({'addon':'ADDON_sales'})"
                v-wjTooltip="'Multifactor AI and Statistical Demand Forecasting'"
            >
                <h2>DEMAND FORECASTING</h2>
                <!-- <p>Multifactor AI and Statistical Demand Forecasting</p> -->
                <img class="img-func" src="images/icon-DEMAND-FORECASTING.png" data-mimetype="image/png">
            </div>
            <div class="button grid-item grid-item-center grid-item2 grid-item-green" @click="onClick({'addon':'ADDON_inventory_optimizer'})"
                v-wjTooltip="'Replenishment planning, optimal stock levels'"
            >
                <!-- <p>Replenishment planning, optimal stock levels</p> -->
                <img class="img-func" src="images/icon-INVENTORY-OPTIMIZER.png" data-mimetype="image/png">
                <h2>INVENTORY OPTIMIZER</h2>
            </div>
            <div class="button grid-item grid-item2 grid-item-blue" @click="onClick({'addon':'ADDON_data_hub'})"
                v-wjTooltip="'Connectors to external ERP, WMS, or Accounting systems'"
            >
                <!-- <p>Connectors to external ERP, WMS, or Accounting systems</p> -->
                <img class="img-func" src="images/icon-DATA-HUB.png" data-mimetype="image/png">
                <h2>DATA HUB</h2>
            </div>
            
            <div class="button center-circle" @click="onClick({'addon':'ADDON_data'})"
                v-wjTooltip="'The core set of data that is essential and shared across the various systems and processes within an organization. It includes key entities such as customers, products, employees, and suppliers.'"
            >
                <img class="img-func2" src="images/icon-master-data.png" data-mimetype="image/png">
                <h2 class="m-0">DATA</h2>
                <!-- <h2 class="logo">WebSCM</h2> -->
                <!-- <div class="p-logo">& analysis tools</div> -->
            </div>
            <img class="img-gear" src="images/icon-gear.png" data-mimetype="image/png">
        </div>
    </Attr>
</template>
  
  <script>
//<template>
//     <Attr :widget_id="widget_id" :attr_id="attr_id">
//         <!-- <div :ref="container"/> -->
//     </Attr>
// </template>

// import { defineComponent } from 'vue';
// export default defineComponent({
//   template: `<h3>Leave3333 blank to automatically generate the default view</h3>`,
// });

import { useMainStore } from '@/stores/mainStore'

export default {
    props: [
        'widget_id',
        'attr_id',
    ],
    // template: `<h3>Leave3333 blank to automatically generate the default view</h3>`,
    data: () => ({ 
        WIDGET: {},
    }),
    setup() {
        const store = useMainStore();
        return { store }
    },
    created() {
        // console.log(`created ${this.attr_id}`)
        this.WIDGET = this.store.findWidget(this.widget_id).WIDGET
        this.content_Changed()
    },
    methods: {
        content_Changed() {
            this.store.widget_attr_set(this)
            
            // const element = document.getElementById(this.attr.html_id); // this.$refs.attr_container
           
            // const container = this.$refs.attrContainer;
            // const child = document.createElement('div');
            // container.appendChild(child.firstElementChild);
           
            // this.$refs.container.innerHTML = this.attr.innerHTML;
        },
        onClick(action) {
            // console.log(action)
            // store.executeStoreMethod(vueObj, dblclick_action)
            
            let row_addon = this.store.attr_find(this.WIDGET.record.doc.processModel_tree, action.addon, 'addon')
            if (row_addon && row_addon.attrs && row_addon.attrs[0]) {
                const item = row_addon.attrs[0]
                this.store.workspace_open(item.node.value)
            }
        }
    }
}
</script>

<style scoped>
.grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 20px;
    position: relative;
}

.grid-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    border-radius: 10px;
    color: white;
    height: 200px;
    transition: transform 0.5s ease-in-out;
}
.grid-item:hover {
    transform: scale(1.03);
}
.grid-item-green { background-color: #45ABB1; }
.grid-item-green2:hover { background-color: #61B8BD; }
.grid-item-blue { background-color: #337ab7; }

.center-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
    height: 200px;
    background-color: white;
    color: #337ab7;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10px;
    flex-direction: column;
    transition: transform 0.5s ease-in-out;
    z-index: 2;
}
.img-gear {
    filter: invert(1);
    position: absolute;
    top: 50%;
    left: 50%;
    transition: transform 0.5s ease-in-out;
    height: 260px;
    margin-top: -130px;
    margin-left: -130px;
    z-index: 1;
}
.center-circle:hover {
    transform: translate(-50%, -50%) scale(1.03);
}
.center-circle:hover + .img-gear {
    transform: rotate(5deg) scale(1.03) !important;
}
.logo {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif;
    font-weight: 900 !important;
    /* letter-spacing: -1.5px !important; */
    background: -webkit-linear-gradient(315deg,#42d392 25%,#647eff);
    -webkit-background-clip:text;
    -webkit-text-fill-color:transparent;
    margin: 5px;
}
.p-logo {
    color: #767676;
}
.img-func {
    height: 70px;
    filter: invert(1);
    margin: 10px;
}
.img-func2 {
    height: 70px;
    filter: opacity(0.6);
    margin: 10px;
}
h2 {
    font-size: calc(1.4rem + 1.8vw);
    font-weight: 900;
}
@media (max-width: 1000px) {
    .grid-container {
        grid-template-columns: 1fr;
    }
    .center-circle {
        width: 180px;
        height: 180px;
    }
    .grid-item {
        flex-direction: row;
        align-items: flex-start;
        text-align: left !important;
        height: 120px;
        justify-content: space-between;
    }
    .grid-item2 {
        align-items: flex-end;
        text-align: right !important;
    }
    .grid-item-center {
        height: 160px;
    }
    h2 {
        font-size: calc(1rem + 1vw);
    }
    .img-gear {
        height: 210px;
        margin-top: -105px;
        margin-left: -105px;
    }
} 
</style>