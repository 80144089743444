<template>
    <Attr :widget_id="widget_id" :attr_id="attr_id">
        <!-- <div class="attr-containerResize" :id="'containerResize'+attr.html_id"> -->
            <div class="label-sheet" v-if="attr.title && !attr.hideTitle" :for="attr.html_id">{{ attr.title }}</div>
            <div class="attr-tree-map" ref="attr_container">
                <wj-tree-map 
                    :id="attr.html_id"
                    labelContent="{name}" 
                    :type="attr.attr_typeTreeMap||'Squarified'" 
                    :initialized="initialized" 
                    :palette="palette"
                    v-visible="handleVisibility"
                />
                    <!-- :binding="attr.tree_binding" -->
                    <!-- :bindingName="attr.tree_bindingName" -->
                    <!-- :labelContent="attr.labelContent||{name}"  -->
                <!-- <div class="wj-error" tabindex="-1">We do need your name...</div> -->
            </div>
        <!-- </div> -->
    </Attr>
</template>

<script>
import { useMainStore } from '@/stores/mainStore'
import '@mescius/wijmo.vue2.chart.hierarchical';
import { isArray } from '@mescius/wijmo';
import { Palettes } from '@mescius/wijmo.chart';

export default {
    props: [
        'widget_id',
        'attr_id',
    ],
    data: () => ({ 
        WIDGET: {},
    }),
    setup() {
        const store = useMainStore();
        return { store }
    },
    created() {
        // console.log(`created ${this.attr_id}`)
        this.WIDGET = this.store.findWidget(this.widget_id).WIDGET
        this.content_Changed()

        this.palette = this.getRandomPalette()
    },
    mounted() {
        this.WIDGET.attrsResize.push(this.attrResize)
    },
    methods: {
        content_Changed() {
            this.store.widget_attr_set(this)
            if (this.flex) {
                this.attrResize()
        
                const [doc, binding] = this.store.attr_get_link(this.WIDGET, this.attr)
                this.doc = doc, this.binding = binding
                this.flex.itemsSource = this.doc[this.binding]

                // if (this.doc[this.binding].length) {
                //     console.log(`this.doc[this.binding].length==${this.doc[this.binding].length} ${this.WIDGET.title}`)
                // } else {
                //     console.log('! this.doc[this.binding].length')
                // }
        
                if (this.flex.itemsSource.length) {
                    this.flex.binding = this.WIDGET.record.doc?.params?.tree_binding || 'quantity' // this.attr.tree_binding
                    this.flex.bindingName = []
                    for (let bindingX of this.attr.tree_bindingName||[]) {
                        let bindingX_added = false
                        if (!bindingX.includes('date') && !bindingX.endsWith('.title')) {
                            for (let attr of this.attr.attrs) {
                                if (attr['binding'] === bindingX) {
                                    if (attr.relation_segment) { //  && attr.relation_segment==this.store.itemsSource.change_type
                                        // console.log(`watch ${this.store.itemsSource.change_type} ${newVal}`)
                                        this.store.get_itemsSource(this, this.doc[this.binding], attr, 'fill.title')
                                        if (this.flex.itemsSource.length && `${attr['binding']}.title` in this.flex.itemsSource[0]) {
                                            this.flex.bindingName.push(`${attr['binding']}.title`)
                                        } else {
                                            this.flex.bindingName.push(`${attr['binding']}`) // LOOK watch...
                                        }
                                        bindingX_added = true
                                        // this.flex.bindingX = `${attr['binding']}.title`
                                        // this.refresh()
                                        break
                                    }
                                }
                            }
                        }
                        if (!bindingX_added) this.flex.bindingName.push(bindingX)
                    }
                }
            }
        },
        initialized(flex) {
            this.flex = flex;
            this.content_Changed()
        },
        getRandomPalette() {
            let palettes = Object.keys(Palettes).filter(prop => isArray(Palettes[prop]));
            let rand = Math.floor(Math.random() * palettes.length);
            //
            return Palettes[palettes[rand]];
        },
        attrResize() {
            if (!this.isVisible) return
            // console.log('attrResize')
            const elementForm = document.getElementById(`WIDGET${this.widget_id}`);
            // const elementForm = document.getElementById(`containerResize${this.attr.html_id}`)
            const elementFlex = document.getElementById(this.attr.html_id); // this.$refs.attr_container
            // const elementFlex = this.$refs.attr_container // document.getElementById(this.attr.html_id);
            if (!elementForm || !elementFlex) {
                return
            }
            const elementWidget_Rect = elementForm.getBoundingClientRect()
            const elementFlex_Rect = elementFlex.getBoundingClientRect()
            if (elementFlex_Rect.height) {
                let newHeight = Math.floor(elementWidget_Rect.bottom - elementFlex_Rect.top) - 2
                if (this.attr.size) {
                    newHeight = Math.round(newHeight * this.attr.size / 100)
                }
                if (newHeight < (this.attr.minHeight||160)) {
                    newHeight = (this.attr.minHeight||160)
                }
                if (this.attr.maxHeight && newHeight > this.attr.maxHeight) {
                    newHeight = this.attr.maxHeight
                } else if (newHeight > 1500) {
                    newHeight = 1500
                }
                if (this.attr.heightAdd) {
                    newHeight += this.attr.heightAdd
                }
                if (elementFlex_Rect.height !== newHeight) {
                    console.log('attrResize' +(elementFlex_Rect.height - newHeight))
                    elementFlex.style.height = `${newHeight}px`
                }
            }
        },
        handleVisibility(isVisible) {
            this.isVisible = isVisible
            if (isVisible) {
                if (this.attr.getSelectionWorkspace_date){
                    this.WIDGET.getSelectionWorkspace_date = this.getSelectionWorkspace_date
                }
            }
        },
    },
    watch: {
        'store.itemsSource_change_index'(newVal, oldVal) {
            let isChanged = false
            for (let bindingX of this.attr.tree_bindingName||[]) {
                let bindingX_added = false
                if (!bindingX.includes('date') && !bindingX.endsWith('.title')) {
                    for (let attr of this.attr.attrs) {
                        if (attr['binding'] === bindingX) {
                            if (attr.relation_segment && attr.relation_segment==this.store.itemsSource.change_type) {
                                // console.log(`watch ${this.store.itemsSource.change_type} ${newVal}`)
                                this.store.get_itemsSource(this, this.doc[this.binding], attr, 'fill.title')
                                isChanged = true
                                // this.flex.bindingName.push(`${attr['binding']}.title`)
                                // bindingX_added = true
                                // this.flex.bindingX = `${attr['binding']}.title`
                                this.content_Changed()
                                // this.flex.refresh()
                                break
                            }
                        }
                    }
                }
                // if (!bindingX_added) this.flex.bindingName.push(bindingX)
            }
            if (isChanged) {
                this.flex.refresh()
            }
        }
    },
}
</script>

<style scoped>
</style>