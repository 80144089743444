<template>
    <Attr :widget_id="widget_id" :attr_id="attr_id">
        <Button 
            :id="attr.html_id"
            :class="buttonClass"
            v-wjTooltip="!store.showTooltip ? '' : tooltip()"
            @click="onClick()"
            @mouseenter="onMouseEnter"
            @mouseleave="onMouseLeave"
            v-if="isVisible"
        >
            <div class="badge" v-if="attr.countItems && !attr['hide-countItems']">{{ store?.formatCount(attr.countItems) }}</div>
            <span v-html="attr.titleCommand || ''"></span>
        </Button>
    </Attr>
</template>

<script>
import { useMainStore } from '@/stores/mainStore'

export default {
    emits: ['mouseenter', 'mouseleave'],
    props: {
        attr_id: String,
        widget_id: [String, Number],
        initialAttr: {
            type: Object,
            default: null,
        },
    },
    data: () => ({
        attr: {},
        WIDGET: {},
        isVisible: false,
    }),
    setup() {
        const store = useMainStore();
        return { store }
    },
    created() {
        // console.log(`created ${this.attr_id}`)
        this.WIDGET = this.store.findWidget(this.widget_id).WIDGET
        this.content_Changed()
        if (this.initialAttr) {
            this.attr = this.initialAttr
        }

        // if (this.attr.shortkey) {
        // //    this.$shortkey(this.attr.hotkey, 'onClick');
        // }
        this.store.prepare_actionMenu(this.attr)
    },
    mounted() {
        this.updateVisibility()
        this.addWatchers()
    },
    methods: {
        content_Changed() {
            this.store.widget_attr_set(this)
        },
        onClick() {
            if (this.WIDGET.widget === 'widget_GLOBAL_SEARCH') {
                this.store.globalSearch.hide()
                this.attr.params = {
                    CHIPS: this.store.globalSearch.CHIPS,
                }
            }

            let vueObj = this.WIDGET.vueObj
            vueObj.store.executeStoreMethod(vueObj, this.attr)
        },
        tooltip() {
            let params = {}
            
            params['Action'] = this.attr.title

            params['Action ID'] = this.attr.action_name
            params['Command'] = this.attr.command
            params['Method'] = this.attr.method
            params['Target field'] = this.attr.binding
            params['Exclude'] = (this.attr.record_sendToBackend_exclude || []).join(', ')

            return this.store.tooltip_html(params, this.attr.tooltip||'')
        },
        onMouseEnter(event) {
            this.$emit('mouseenter', event, this);
        },
        onMouseLeave(event) {
            this.$emit('mouseleave', event, this);
        },
        updateVisibility() {
            this.isVisible =
                this.attr.visible !== false &&
                this.attr.hide !== true &&
                this.store?.eval_value(this.WIDGET, this.attr['v-if'], {}, this.attr)
        },
        addWatchers() {
            if (this.attr['v-if']) {
                this.$watch( () => this.attr, (newVal, oldVal) => {
                    this.updateVisibility()
                }, { deep: true } )

                this.$watch( () => this.WIDGET.record?.id, (newVal, oldVal) => {
                    this.updateVisibility()
                })

                this.$watch( () => this.WIDGET.record?.doc?.params, (newVal, oldVal) => {
                    this.updateVisibility()
                }, { deep: true } )

                this.$watch( () => this.WIDGET.record?._meta, (newVal, oldVal) => {
                    this.updateVisibility()
                }, { deep: true } )

                this.$watch( () => this.WIDGET._view, (newVal, oldVal) => {
                    this.updateVisibility()
                }, { deep: true } )
            }
    },
    },
    computed: {
        buttonClass() {
            return [
                'commandPanel-buttons p-button-sm p-button-no-border p-2 ' + (this.attr.cssClass2||'') + (this.attr.cssClass||''), {
                'p-button-active': this.attr.active,
                // p-button-secondary p-button-text0 
            }]
        },
    },
    watch: {
        // attr: {
        //     handler: 'updateVisibility',
        //     deep: true
        // },
        // 'WIDGET.record.id': {
        //     handler: 'updateVisibility',
        //     deep: true
        // },
        // 'WIDGET.record._meta': {
        //     handler: 'updateVisibility',
        //     deep: true
        // }
    },
}
</script>

<style>
.commandPanel-buttons {
    overflow: visible;
}
</style>