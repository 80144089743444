<template>
    <Attr :widget_id="widget_id" :attr_id="attr_id">
        <div class="attr-tree-view" ref="attr_container" tabindex="0">
            <div class="commandPanel">
                <div class="commandPanel-menu">
                    <component
                        v-for="attrM in commandPanel"
                        :key="attrM.html_id"
                        :widget_id="widget_id" 
                        :is="attrM.component"
                        :attr_id="attrM.id"
                        :initialAttr="attrM" 
                    />
                </div>
                <div>
                    <label for="search">Search: </label>
                    <wj-auto-complete 
                        :items-source="autoCompleteData"
                        display-member-path="path"
                        :selected-index="-1"
                        search-member-path="keywords"
                        :selected-index-changed="search_selectedIndexChanged" />
                    <label class="label-sheet" v-if="attr.title && !attr.hideTitle" :for="attr.html_id">{{ attr.title }}</label>
                </div>
            </div>
            <wj-tree-view
                :itemsSource="doc?.[binding]||[]"
                displayMemberPath="title"
                childItemsPath="attrs"
                imageMemberPath="img"
                :allowDragging="attr.allowDragging"
                :drag-start="onDragStart"
                :drag-over="onDragOver"
                :drag-end="onDragEnd"
                :autoCollapse="false"
                :initialized="initialized"
                :isReadOnly="attr.isReadOnly||false"
                :selectedItemChanged="selectedItemChanged"
                @click="click"
                @dblclick="dblclick"
                :formatItem="formatItem"
                :gotFocus="gotFocus"
                :nodeEditStarting="nodeEditStarting"
                v-wjContextMenu="'ContextWidgetMenuId'"
            />
                <!-- :showCheckboxes="attr.showCheckboxes||false" -->
                <!-- :checkedMemberPath="'use'" -->
                <!-- :checkedItemsChanged="checkedItemsChanged" -->
            <div v-if="attr['wj-error']" class="wj-error" tabindex="-1">{{ attr['wj-error'] }}</div>
        </div>
    </Attr>
</template>

<script>
import { useMainStore } from '@/stores/mainStore'
import * as WjNav from '@mescius/wijmo.nav';

export default {
    props: [
        'widget_id',
        'attr_id',
    ],
    data: () => ({ 
        WIDGET: {},
        doc: {},
        binding: '',
        autoCompleteData: [],
        allowDraggingParentNodes: true,
        allowDroppingIntoEmpty: true,
        commandPanel: [],
        buttons_innerHTML: '',
        attr_types: {
            AttrStr: {title: 'Text', pi:'pi-minus'},
            AttrBool: {title: 'Boolean', pi:'pi-check-square'},
            AttrNumber: {title: 'Number', pi:'pi-minus'},
            AttrDate: {title: 'Date', pi:'pi-calendar'},
            AttrTime: {title: 'Time', pi:'pi-calendar'},
            AttrLink: {title: 'Link', pi:'pi-link'},

            // AttrItemsSource: {title: 'Table', pi:'pi-table'},
            AttrGrid: {title: 'Grid', pi:'pi-table'},
            AttrSheet: {title: 'Sheet', pi:'pi-table'},
            AttrHTML: {title: 'HTML', pi:'pi-sitemap'},
            AttrLabel: {title: 'Label', pi:'pi-sitemap'},
            AttrFlowchart: {title: 'Model', pi:'pi-sitemap'},
            AttrTree: {title: 'Tree', pi:'pi-sitemap'},
            AttrGantt: {title: 'Gantt', pi:'pi-sitemap'},
            AttrTabPanel: {title: 'Tab panel', pi:'pi-book'},
            AttrTab: {title: 'Tab', pi:'pi-folder'},
            AttrTabRow: {title: 'Tab row', pi:'pi-folder'},
            AttrJSON: {title: 'JSON', pi:'pi-minus'},
            AttrStrMulti: {title: 'Text multi', pi:'pi-minus'},
            AttrButton: {title: 'Button', pi:'pi-minus'},
            AttrChart: {title: 'Chart', pi:'pi-chart-line'},
            AttrBubbleChart: {title: 'Bubble chart', pi:'pi-chart-line'},
            AttrBubbleWithAnimationChart: {title: 'Bubble with animation chart', pi:'pi-chart-line'},
            AttrTreeMap: {title: 'Tree map', pi:'pi-chart-line'},
            AttrPivotTool: {title: 'Pivot tool', pi:'pi-chart-line'},
        },
    }),
    setup() {
        const store = useMainStore();
        return { store }
    },
    created() {
        // console.log(`created ${this.attr_id}`)
        this.WIDGET = this.store.findWidget(this.widget_id).WIDGET
        this.content_Changed()
    },
    methods: {
        content_Changed() {
            this.store.widget_attr_set(this)

            let [doc, binding] = this.store.attr_get_link(this.WIDGET, this.attr)
            if (!Array.isArray(doc[binding])) {
                this.store.attr_set(this.WIDGET, this.attr, []);
                [doc, binding] = this.store.attr_get_link(this.WIDGET, this.attr)
            }

            this.doc = doc, this.binding = binding
            this.autoCompleteData = this.store.getSearchList(doc[binding])
            this.store.clear_modified(doc[binding])
            if (this.attr.groupMenu && this.attr.groupMenu.cell_buttons) {
                this.buttons_innerHTML = this.store.get_buttons_innerHTML(this.attr.groupMenu.cell_buttons)
            }
            this.set_commandPanel()
        },
        formatItem(s, e) {
            // console.log(`formatItem ${e.dataItem.title}`)
            let doc = e.dataItem, // e.itemsSource[e.index],
                title = doc.title,
                pi = doc['@treeNode'] ? doc['@treeNode'].pi : '',
                tooltip = ''
            if (['fields','view','viewList'].includes(this.attr.configType)) {
                if (this.attr_types[doc.attr_type]) {
                    tooltip = this.attr_types[doc.attr_type].title
                    if (this.attr_types[doc.attr_type].pi) pi = this.attr_types[doc.attr_type].pi
                }
                if (doc.attr_type === 'AttrLink' && doc.relation_segment && this.store.itemsSource.SEGMENTS.ids[doc.relation_segment]) {
                    tooltip += `: ${this.store.itemsSource.SEGMENTS.ids[doc.relation_segment].title}`
                }
                // if (this.attr.configType === 'fields') {
                //     title = doc.binding
                // }
            } else if (this.attr.configType=='tasks') {
                if (doc['@treeNode']) {
                    tooltip = doc['@treeNode'].tooltip || ''
                }
            }
            if (!title) {
                if (doc.title) {
                    title = `{title: ${doc.title}}`
                } else if (doc.field) {
                    title = `{field: ${doc.field}}`
                } else if (doc.binding) {
                    title = `{binding: ${doc.binding}}`
                } else if (doc.tooltip) {
                    title = `{tooltip: ${doc.tooltip}}`
                } else if (doc.workspace) {
                    title = `{workspace: ${doc.workspace}}`
                } else if (doc.widget) {
                    title = `{widget: ${doc.widget}}`
                } else if (doc.menuTree) {
                    title = `{menuTree: ${doc.menuTree}}`
                    if (doc.command) {
                        title = `{command: ${doc.command}}`
                    }
                    if (doc.method) {
                        title = `{method: ${doc.method}}`
                    }
                } else if (doc.node) {
                    title = `{node: ${doc.node}}`
                } else {
                    title = `___________________`
                }
            }
            let buttons_innerHTML = ''
            if (this.buttons_innerHTML) {
                buttons_innerHTML = 
                `<div class="data-cell attr-doc-button-group wj-control">
                    ${this.buttons_innerHTML}
                </div>`
            }
            e.element.innerHTML = e.element.innerHTML.substring(0, e.element.innerHTML.indexOf('<span class="wj-node-text">'));
            e.element.innerHTML += 
            `<span class="attr-doc wj-node-text ${doc['@treeNode'] ? doc['@treeNode'].cssClass : ''} ${doc.use==false ? 'wj-node-text-unchecking': ''} ${doc._meta?.modified ? 'wj-node-modified': ''}">
                <div class="wj-node-my-cell">
                    <i class="pr-2 pi ${pi}"></i>
                    ${title}
                    <div class="text-properties">
                        ${tooltip ? `<div class="wj-node-my-right">${tooltip}</div>` : ''}
                    </div>
                    ${buttons_innerHTML}
                </div>
            </span>`;
        },
        onDragStart(tree, e) {
            if (e && e.node && e.node.hasChildren) {
                if (!this.allowDraggingParentNodes) {
                    e.cancel = true; // prevent dragging parent nodes
                } else {
                    e.node.isCollapsed = true; // collapse parent nodes when dragging
                }
            }
        },
        onDragOver(tree, e) {
            if (!this.allowDroppingIntoEmpty && !e.dropTarget.hasChildren && e.position === WjNav.DropPosition.Into)
            {
                e.position = WjNav.DropPosition.Before;
            }
        },
        onDragEnd(tree, e) {
            tree.selectedNode.dataItem._meta = tree.selectedNode.dataItem._meta || {}
            tree.selectedNode.dataItem._meta.modified = true
            this.store.tree_WidgetatItem(tree, tree.selectedNode)
        },
        initialized(tree) {
            this.tree = tree
            this.flex = tree // for get_flex()
        },
        click(e) {
            if (this.attr.groupMenu && this.attr.groupMenu.cell_buttons) {
                let vueObj = this.WIDGET.vueObj
                this.store.executeCommand_by_html_id(vueObj, this.attr.groupMenu.cell_buttons, e.target.id)
            }
        },
        get_dblclick_action(tree, e) {
            if (this.get_isReadOnly()) {
                let vueObj = this.WIDGET.vueObj
                this.store.get_dblclick_action(vueObj, this.attr)
            }
        },
        search_selectedIndexChanged(s) {
            if (s.selectedItem && this.tree) {
                this.tree.selectedItem = s.selectedItem.attr;
            }
        },
        selectedItemChanged(tree, e) {
            // this.store.rightArea_setDataSource(this, this.attr.binding, this.attr.configType)
        },
        gotFocus(tree, e) {
            // this.store.rightArea_setDataSource(this, this.attr.binding, this.attr.configType)
        },
        checkedItemsChanged(s) {
            // this.store.rightArea_setDataSource(this, this.attr.binding, this.attr.configType)
        },
        nodeEditStarting(tree, e) {
            e.cancel = !this.attr.edit_displayMember || this.get_viewParam('isReadOnly') // Otherwise it's ruined title in formatItem
            // console.log('nodeEditStarting')
        },

        get_viewParam(param, defaultValue) {
            return this.store.get_viewParam(this.WIDGET, this.attr.name, param, defaultValue, this.attr)
        },
        set_viewParam(param, value) {
            this.store.set_viewParam(this.WIDGET, this.attr.name, param, value, this.attr)

            if (param === 'isReadOnly') {
                this.set_viewParam('isReadOnly', value)
                
                this.flex.isReadOnly = value
                this.flex.allowDragging = !value
                this.set_commandPanel()
            }
        },
        set_commandPanel() {
            let eval_params = {}
            this.WIDGET.attrs_vueObj.WIDGET.set_commandPanel(eval_params)

            if (this.attr && 'groupMenu' in this.attr && 'commandPanel' in this.attr.groupMenu) {
                this.commandPanel = []
                // this.commandPanel = this.attr.groupMenu.commandPanel
                // while (this.commandPanel.length > 0) {
                //     this.commandPanel.pop()
                // }
                for (let command_ of this.attr.groupMenu.commandPanel) {
                    const command = command_, v_if = command_['v-if'] // { 'v-if': v_if, ...command } = command_
                    command.visible = this.store.eval_value(this.WIDGET, v_if, eval_params)
                    this.commandPanel.push(command)
                }
                // this.attr.groupMenu.commandPanel.pop() 
                // console.log(this.attr.groupMenu.commandPanel)
            }
        },
    },
}
</script>

<style>
.attr-tree-view {
    width:-webkit-fill-available !important;
    /* margin: 0px;
    padding: 0px; */
}
.attr-tree-view .commandPanel{
    display: flex;
    width:-webkit-fill-available !important;
    padding: 6px;
}
.attr-tree-view .commandPanel-menu{
    padding: 0 6px;
}
.node0 {
    /* font-size: 120%; */
    font-weight: bold;
    padding: 6px 10px;
    color: white;
    background: #778899;
    /* margin-bottom: 4px; */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    width: 98%;
    border-radius: 0.5rem;
}
.node1 {
    /* font-size: 120%; */
    font-weight: bold;
    padding: 6px 10px;
    /* color: white; */
    background: #ecf0f3;
    /* margin-bottom: 4px; */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    width: 98%;
    border-radius: 0.5rem;
}
/* .wj-node .pi {
    padding-right: 6px;
} */
.wj-node-my-cell {
    display: flex;
}
.wj-node-text-unchecking {
    text-decoration: line-through;
}
.wj-node-modified {
    background-color: lightgoldenrodyellow;
    padding: 6px 10px;
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.wj-node-my-right {
    right: 24px;
    position: absolute;
    text-shadow:
            /* many shadows blur out the area around the text */
            0 0 1px white,
            0 0 2px white,
            0 0 3px white,
            0 0 4px white,
            0 0 5px white,
            0 0 6px white,
            0 0 7px white,
            0 0 8px white,
            0 0 9px white,
            0 0 10px white
}
.text-properties {
    color: #778899;
}
.wj-node {
    position: relative;
}
</style>