<template>
    <Attr :widget_id="widget_id" :attr_id="attr_id">
        <div class="wj-labeled-input attr-date" :style="attr.cssStyle||'max-width: 8rem !important;'" v-wjTooltip="tooltip()">
            <wj-input-date 
                :class="'attr-element '+attr.cssClass"
                :id="attr.html_id"
                :isReadOnly="isReadOnly"
                :isRequired="false"
                :value="date"
                :valueChanged="attr_set"
                :format="this.attr.format||'yyyy-MM-dd'"
                :invalidInput="invalidInput"
                @keydown.enter="handleEnterKey"
                :initialized="initialized"
                v-wjContextMenu="'ContextWidgetMenuId'"
                />
                <!-- :value="date" -->
                <!-- :format="'p0'" -->
                <!-- :step=".05" -->
                <!-- :min="0" -->
                <!-- :max=".2" -->
            <label v-if="attr.title && !attr.hideTitle" :for="attr.html_id">{{ attr.title }}</label>
            <!-- <div class="wj-error" tabindex="-1">We do need your name...</div> -->
        </div>
    </Attr>
</template>

<script>
import { useMainStore } from '@/stores/mainStore'
import * as wjcCore from '@mescius/wijmo';

export default {
    props: [
        'widget_id',
        'attr_id',
    ],
    data: () => ({ 
        WIDGET: {},
        date: '',
        doc: {},
        binding: '',
    }),
    setup() {
        const store = useMainStore();
        return { store }
    },
    created() {
        // console.log(`created ${this.attr_id}`)
        this.WIDGET = this.store.findWidget(this.widget_id).WIDGET
        this.content_Changed()
    },
    methods: {
        content_Changed() {
            this.store.widget_attr_set(this)
        
            const [doc, binding] = this.store.attr_get_link(this.WIDGET, this.attr)
            this.doc = doc, this.binding = binding

            this.dateStr = doc[binding] // this.store.attr_get(this.WIDGET, this.attr)
            // date = wjcCore.asDate(date)
            let parseDate = wjcCore.Globalize.parseDate
            let date = parseDate(this.dateStr, 'yyyy-MM-dd')
            if (!date) {
                date = parseDate(this.dateStr)
            }
            if (!date) {
                date = this.store.parseDateString(this.dateStr)
            }
            this.date = date
        },
        initialized(input) {
            this.input = input
        },
        attr_set() {
            let formatDate = wjcCore.Globalize.formatDate
            if (this.input.value) {
                this.dateStr = formatDate(this.input.value, 'yyyy-MM-dd') // this.input.value.toISOString()
                this.store.attr_set(this.WIDGET, this.attr, this.dateStr)
            } else {
                this.store.attr_set(this.WIDGET, this.attr, null)
            }
        },
        invalidInput() {
            this.date = this.store.parseDateString(this.input.text)
        },
        handleEnterKey() {
            this.date = this.store.parseDateString(this.input.text)
        },
        tooltip() {
            return this.store.tooltip_attr(this)
        },
        // tooltipChange(e, tooltip_element_id) {
        //     this.store.tooltipChange(this, e, tooltip_element_id)
        // },
    },
	computed: {
        isReadOnly() {
            return this.WIDGET.isReadOnly||this.attr.isReadOnly||this.attr.fieldMapping?.use||false
        },
        doc_binding() {
            return this.doc?.[this.binding]
        },
	},
    watch: {
        'doc_binding'(newValue, oldValue) {
            if (this.dateStr !== newValue) {
                this.content_Changed()
            }
        }
    },
}
</script>

<style>
.attr-date {
    width:-webkit-fill-available !important;
}
.attr-element {
    border-radius: 0.3rem;
}
</style>