<template>
    <Attr :widget_id="widget_id" :attr_id="attr_id">
        <div class="button-separator"></div>
    </Attr>
</template>
  
  <script>
//<template>
//     <Attr :widget_id="widget_id" :attr_id="attr_id">
//         <!-- <div :ref="container"/> -->
//     </Attr>
// </template>

// import { defineComponent } from 'vue';
// export default defineComponent({
//   template: `<h3>Leave3333 blank to automatically generate the default view</h3>`,
// });

import { useMainStore } from '@/stores/mainStore'

export default {
    props: {
        attr_id: String,
        widget_id: [String, Number],
        initialAttr: {
            type: Object,
            default: null,
        },
    },
    // template: `<h3>Leave3333 blank to automatically generate the default view</h3>`,
    data: () => ({ 
        WIDGET: {},
    }),
    setup() {
        const store = useMainStore();
        return { store }
    },
    created() {
        // console.log(`created ${this.attr_id}`)
        this.WIDGET = this.store.findWidget(this.widget_id).WIDGET
        this.content_Changed()
    },
    methods: {
        content_Changed() {
            this.store.widget_attr_set(this)
            
            // const element = document.getElementById(this.attr.html_id); // this.$refs.attr_container
           
            // const container = this.$refs.attrContainer;
            // const child = document.createElement('div');
            // container.appendChild(child.firstElementChild);
           
            // this.$refs.container.innerHTML = this.attr.innerHTML;
        },
    }
}
</script>

<style scoped>
.button-separator {
    width: 10px;
}
</style>