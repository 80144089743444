<template>
    <div
        v-if="visible"
        class="globalSearch"
        ref="searchBox"
        :style="computedPosition"
    >
        <div class="search-input">
            <i :class="['pi', 'p-2', pi]"></i>

            <div class="grid-chips">
                <span class="p-float-label">
                    <Chips ref="chips" inputId="chips" v-model="CHIPS"
                        @add="apply_globalSearch"
                        @remove="apply_globalSearch"
                        @update:modelValue="apply_globalSearch"
                    ></Chips>
                    <!-- <label for="chips">Search...</label> -->
                </span>
            </div>
    
            <div class="commandPanel" v-if="commandPanel.length">
                <component
                    v-for="attrM in commandPanel"
                    :key="attrM"
                    :widget_id="widget_id" 
                    :is="attrM.component"
                    :attr_id="attrM.id"
                    :initialAttr="attrM"
                />
            </div>
        </div>

        <div class="search-collections">
            <div v-for="(item, index) in answer.data_collection" :key="index" @click="addInSearch('collection', item.collection, item['collection.title'])" class="search-item">
                <span class="meta-chip collection" v-wjTooltip="'Add collection in search'">● {{ item['collection.title'] }} {{ this.store.formatCount(item.count) }}</span>
            </div>
            <div v-for="(item, index) in answer?.data_segments||[]" :key="index" @click="addInSearch('segment', item.segment, item['segment.title'])" class="search-item">
                <span class="meta-chip segment" v-wjTooltip="'Add segment in search'">● {{ item['segment.title'] }} {{ this.store.formatCount(item.count) }}</span>
            </div>
            <div v-for="(item, index) in answer?.data_statuses||[]" :key="index" @click="addInSearch('status', item.status, item['status.title'])" class="search-item">
                <span class="meta-chip status" v-wjTooltip="'Add status in search'">● {{ item['status.title'] }} {{ this.store.formatCount(item.count) }}</span>
            </div>
            <div v-for="(item, index) in answer?.data_widgets||[]" :key="index" @click="addInSearch('widget', item.widget, item['widget.title'])" class="search-item">
                <span class="meta-chip widget" v-wjTooltip="'Add widget in search'">● {{ item['widget.title'] }} {{ this.store.formatCount(item.count) }}</span>
            </div>
        </div>

        <div v-if="answer?.data?.length" class="search-results">
            <ul>
                <li v-for="(item, index) in answer.data" :key="index" @click="selectItem('open', item)" class="search-item">
                    <div class="search-content" v-if="command === 'globalSearch'">
                        <div class="order-number">
                            <span class="search-id" v-html="item.highlightedID"></span>
                            <span class="meta link" @click.stop="selectItem('copyToClipboard', item)" v-wjTooltip="'Copy to clipboard'"> <i class="pi pi-copy"></i> </span>
                            <span class="meta link" @click.stop="selectItem('openInNewTab', item)" v-wjTooltip="'Open record in new tab'"> <i class="pi pi-external-link"></i> </span>
                            <span class="meta-chip collection" @click.stop="addInSearch('collection', item._meta.collection, item['_meta.collection.title'])" v-wjTooltip="'Add collection in search'">● {{ item['_meta.collection.title'] }}</span>
                            <span class="meta-chip segment" @click.stop="addInSearch('segment', item.segment, item['segment.title'])" v-wjTooltip="'Add segment in search'">● {{ item['segment.title'] }}</span>
                        </div>
                        <div class="search-title" v-html="item.highlightedTitle"></div>
                        <div class="search-info-0">
                            <span class="meta field">• Field: {{ item._meta.binding }}</span>
                            <span class="meta date">• Updated at: {{ (item.updated_at||'').slice(0, 19) }}</span>
                        </div>
                        <div class="search-info-0">
                            <span class="meta value">• Value: <span v-html="(item._meta.highlightedValue || '').slice(0, 100)"></span></span>
                        </div>
                    </div>
                    <div class="search-content" v-else-if="command === 'globalHistory'">
                        <div class="order-number">
                            <span class="search-id" v-html="item.record_id"></span>
                            <span class="meta link" @click.stop="store?.externalLink({method:'copyToClipboard', action_id:item.id})" v-wjTooltip="'Copy to clipboard'"> <i class="pi pi-copy"></i> </span>
                            <span class="meta link" @click.stop="store?.externalLink({method:'openInNewTab', action_id:item.id})" v-wjTooltip="'Open record in new tab'"> <i class="pi pi-external-link"></i> </span>
                            <span class="meta-chip segment" @click.stop="addInSearch('segment', item.segment, item['segment.title'])" v-wjTooltip="'Add segment in search'">● {{ item['segment.title'] }}</span>
                            <span class="meta-chip widget" @click.stop="addInSearch('widget', item.widget, item['widget.title'])" v-wjTooltip="'Add widget in search'">● {{ item['widget.title'] }}</span>
                        </div>
                        <div class="search-title" v-html="item.highlightedTitle"></div>
                        <div class="search-info-0">
                            <span class="meta date">• Viewed at: {{ (item.viewed_at||'').slice(0, 19) }}</span>
                        </div>
                    </div>
                    <div class="search-content" v-else-if="command === 'globalLog'">
                        <div class="order-number">
                            <span class="search-id" v-html="item.highlightedID"></span>
                            <span class="meta link" @click.stop="selectItem('copyToClipboard', item)" v-wjTooltip="'Copy to clipboard'"> <i class="pi pi-copy"></i> </span>
                            <span class="meta link" @click.stop="selectItem('openInNewTab', item)" v-wjTooltip="'Open record in new tab'"> <i class="pi pi-external-link"></i> </span>
                            <!-- <span class="meta-chip collection" @click.stop="addInSearch('collection', item._meta.collection, item['_meta.collection.title'])" v-wjTooltip="'Add in search'">● {{ item['_meta.collection.title'] }}</span> -->
                            <span class="meta-chip segment" @click.stop="addInSearch('segment', item.segment, item['segment.title'])" v-wjTooltip="'Add segment in search'">● {{ item['segment.title'] }}</span>
                            <span class="meta-chip widget" @click.stop="addInSearch('widget', item.widget, item['widget.title'])" v-wjTooltip="'Add widget in search'">● {{ item['widget.title'] }}</span>
                            <span class="meta-chip status" @click.stop="addInSearch('status', item.status, item['status.title'])" v-wjTooltip="'Add status in search'">● {{ item['status.title'] }}</span>
                        </div>
                        <div class="search-title" v-html="item.highlightedTitle"></div>
                        <div class="search-info-0">
                            <span class="meta duration">• Duration: {{ item['duration.title'] }}</span>
                            <span class="meta date">• Updated at: {{ (item.created_at||'').slice(0, 19) }}</span>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <div class="external-search" @click="searchMore">
            <div>{{ formattedText }}</div>
        </div>
    </div>
</template>

<script>
import { useMainStore } from '@/stores/mainStore'
import Widget from './Widget.vue'

export default {
    props: {
        command: { // globalSearch / globalHistory / globalLog
            type: String,
            required: true,
        }
    },
    data() {
        return {
            // widget_id:'globalSearch',
            visible: false,
            position: { top: 5, left: 250, width: 700 },
            answer: {},
            CHIPS: [],
            searchQuery: '...',
            commandPanel: [
                // { name: 'command-search', title: 'Search', cssClass: 'pi p-button-white p-2 pi-clone', command:'widget_open', widget:'widget-search'},
            ],
            pi: 'pi-search',
        }
    },
    setup() {
        const store = useMainStore()
        
        return { store }
    },
    created() {
        this.widget_id = this.store.nextWidget_id++
        this.WIDGET = this.store.WIDGETS[this.widget_id] = {
            id: this.widget_id,
            workspace: 'workspace-store',
            page: 'page-store',
            widget: 'widget_GLOBAL_SEARCH',
            vueObj: this,
            attrs_vueObj: {
                WIDGET: this,
            },
        }
        this.store.prepare_attrs(this.commandPanel, 'globalSearch')
        if (this.command === 'globalHistory') {
            this.pi = 'pi-hourglass'
        } else if (this.command === 'globalLog') {
            this.pi = 'pi-history'
        }
    },
    mounted() {
    },
    methods: {
        show(search_value, activeElement) {
            if (search_value) {
                this.CHIPS.splice(0, this.CHIPS.length, {
                    use: true,
                    pi: 'pi-search',
                    attr_type: 'AttrChipSearch', 
                    title: search_value
                });
            }
            this.activeElement = activeElement
            this.visible = true
            this.apply_globalSearch()

            this.store.dropdown?.hide()

            this.handleClickOutside = (event) => {
                if (this.$refs.searchBox && !this.store.mouse_isInside(this.$refs.searchBox)) { // !this.$refs.searchBox.contains(event.target)
                    this.hide()
                }
            }

            this.handleKeydown = (event) => {
                if (event.key === "Escape") {
                    this.hide()
                    if (this.activeElement) {
                        this.activeElement.focus()
                    }
                }
            }

            setTimeout(() => {
                document.addEventListener("click", this.handleClickOutside)
                document.addEventListener("keydown", this.handleKeydown)

                if (this.$refs.chips) {
                    const inputElement = this.$refs.chips.$el.querySelector("input")
                    if (inputElement) {
                        inputElement.focus()
                    }
                }
            }, 100)
        },
        hide() {
            this.visible = false
            document.removeEventListener("click", this.handleClickOutside)
            document.removeEventListener("keydown", this.handleKeydown)
        },
        apply_globalSearch() {
            if (this.CHIPS.length || this.command !== 'globalSearch') {
                this.store.executeBackendMethod(this, {
                    command: this.command,
                    params: {
                        CHIPS: this.CHIPS,
                        limit: 5,
                    }
                }, this.load_globalSearch)
            } else {
                this.data = []
                this.answer = {}
            }
        },
        load_globalSearch(answer) {
            this.answer = answer
            this.store.get_itemsSource(this, answer.data, {binding:'segment', attr_type:'AttrLink', relation_segment:'SEGMENTS-UNION'}, 'fill.title')
            this.store.get_itemsSource(this, answer.data, {binding:'_meta.collection', attr_type:'AttrLink', relation_segment:'SEGMENTS-UNION'}, 'fill.title')

            this.store.get_itemsSource(this, answer.data_collection, {binding:'collection', attr_type:'AttrLink', relation_segment:'SEGMENTS-UNION'}, 'fill.title')
            this.store.get_itemsSource(this, answer.data_segments, {binding:'segment', attr_type:'AttrLink', relation_segment:'SEGMENTS-UNION'}, 'fill.title')
            
            // log
            this.store.get_itemsSource(this, answer.data, {binding:'widget', attr_type:'AttrLink', relation_segment:'WIDGETS-TASKS'}, 'fill.title')
            this.store.get_itemsSource(this, answer.data, {binding:'status', attr_type:'AttrLink', relation_segment:'STATUS'}, 'fill.title')
            
            this.store.get_itemsSource(this, answer.data_widgets, {binding:'widget', attr_type:'AttrLink', relation_segment:'WIDGETS-TASKS'}, 'fill.title')
            this.store.get_itemsSource(this, answer.data_statuses, {binding:'status', attr_type:'AttrLink', relation_segment:'STATUS'}, 'fill.title')

            for (const item of answer.data) {
                item['duration.title'] = this.store.humanDuration(item.duration)
            }

            for (const item of answer.data) {
                item.highlightedID = this.store.check_CHIPS_highlighted(this.WIDGET, item.id, this.CHIPS)
                item.highlightedTitle = this.store.check_CHIPS_highlighted(this.WIDGET, item.title, this.CHIPS)
                item._meta.highlightedValue = this.store.check_CHIPS_highlighted(this.WIDGET, item._meta.value, this.CHIPS)
            }
        },
        selectItem(method, item) {
            if (method === 'open') {
                this.store.executeStoreMethod(this, { action_name:'action-front-2025-01-29-1', command:'widget_open', widget:'widget-record', item })
                this.hide()
            } else if (method === 'copyToClipboard') {
                this.store.executeStoreMethod(this, { action_name:'action-front-2025-01-29-2', command:'widget_open', widget:'widget-record', item, externalLink_method:method })
            } else if (method === 'openInNewTab') {
                this.store.executeStoreMethod(this, { action_name:'action-front-2025-01-29-3', command:'widget_open', widget:'widget-record', item, externalLink_method:method })
                this.hide()
            }
        },
        addInSearch(binding, value, title) {
            this.$refs.chips?.setChipWhere(title, binding, '=', value)
            this.apply_globalSearch()
        },
        searchMore() {
            if (this.command === 'globalSearch') {
                this.store.executeStoreMethod(this, { title:'Search more...', command:'widget_open', widget:'widget-search', params: {
                    CHIPS: this.CHIPS,
                } })
            } else if (this.command === 'globalHistory') {
                this.store.executeStoreMethod(this, { title:'View more activities...', command:'widget_open', widget:'widget-historys', params: {
                    CHIPS: this.CHIPS,
                } })
            } else if (this.command === 'globalLog') {
                this.store.executeStoreMethod(this, { title:'Log more...', command:'widget_open', widget:'widget-logs', params: {
                    CHIPS: this.CHIPS,
                } })
            }

            this.hide()
        },
    },
    computed: {
        computedPosition() {
            const position = this.position
            return { top: position.top + 'px', left: position.left + 'px', width: position.width + 'px' }
        },
        formattedText() {
            let textMore = 'Search more...'

            if (this.command === 'globalHistory') {
                textMore = 'View more activities...'
            } else if (this.command === 'globalLog') {
                textMore = 'Log more...'
            }

            return `${textMore} ${this.store.formatCount(this.answer?.data_count)} records`
        },
    }
}
</script>

<style scoped>
.globalSearch {
    position: absolute;
    z-index: 1000;
    background: white;
    border: 3px solid rgba(255,150,30,.75); 
    border-radius: 0.5rem;
    list-style: none;
    padding: 0;
    margin: 0;

    display: flex;
    flex-direction: column;

    box-shadow: 0 1px 2px rgba(55, 63, 66, 0.07), 0 2px 4px rgba(55, 63, 66, 0.07), 0 4px 8px rgba(55, 63, 66, 0.07), 0 8px 16px rgba(55, 63, 66, 0.07), 0 16px 24px rgba(55, 63, 66, 0.07), 0 24px 32px rgba(55, 63, 66, 0.07);
}
.globalSearch .search-input {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
}
.search-results {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin-top: 10px;
}
.search-item {
    padding: 10px;
    cursor: pointer;
    transition: background 0.2s;
    border-bottom: 1px solid #eee;
  }
  
.search-item:hover {
    background: #f5f5f5;
}
.meta {
    font-size: 12px;
    margin-left: 8px;
    padding: 2px 5px;
    border-radius: 3px;
}
.meta-chip {
    margin-left: 8px;
    padding: 2px 5px;
    border-radius: 3px;

    font-stretch: ultra-condensed;
    font-size: 1.3rem;
    font-weight: bold;
}
.value {
    font-stretch: ultra-condensed;
}

.search-id {
    font-weight: lighter;
}
.search-title {
    font-size: large;
    padding: 5px;
}
.external-search {
    background: #f5f5f5;
    cursor: pointer;
    padding: 15px;
}
.search-content {
    display: flex;
    flex-direction: column;
}
.search-info {
    display: flex;
}
.search-collections {
    display: flex;
    flex-wrap: wrap;
}
</style>